import styled from 'styled-components';
import { Card, Row, Table } from 'antd';

export const StyledTable = styled(Table)`
  --header-color: linear-gradient(135deg, #252f64, #536390);
  --header-text-color: #ffffff;
  --active-header-text-color: #000000;

  .ant-table {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .ant-table-thead > tr > th {
    background-image: var(--header-color);
    color: var(--header-text-color);
    font-weight: bold;
    font-size: 14px;
    border-bottom: 2px solid #ffffff;
  }

  .ant-table-thead > tr > th:hover {
    background-color: var(--header-hover-color);
    color: #252f64;
    cursor: pointer;
  }

  .ant-table-thead > tr > th.ant-table-column-sort:hover {
    background-color: var(--header-hover-color);
    color: #252f64;
  }

  .ant-table-thead > tr > th.ant-table-column-sort {
    color: var(--active-header-text-color);
  }

  .ant-table-tbody > tr:nth-child(odd) > td {
    background-color: var(--odd-row-color);
  }

  .ant-table-tbody > tr:nth-child(even) > td {
    background-color: var(--even-row-color);
  }

  .ant-table-tbody > tr > td {
    color: #252f64 !important;
    font-size: 12px;
    font-weight: 500;
  }

  .ant-table-tbody > tr:hover > td {
    background-color: #cbcedd !important;
    transition: background-color 0.1s ease, color 0.1s ease, border-color 0.1s ease;
  }

  .ant-table-row {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .ant-table-expand-icon-th,
  .ant-table-row-expand-icon {
    display: none !important;
  }

  .ant-table-row-expand-icon {
    display: none !important;
  }
`;

export const StyledCard = styled(Card)`
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  cursor: pointer;
  background: linear-gradient(135deg, #252f64, #536390);
  color: white;
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  height: 150px;

  &:hover {
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
    transform: translateY(-1px);
  }

  .ant-card-head-title {
    font-size: clamp(0.6rem, 1.2vw + 0.5rem, 1rem);
    font-weight: 500;
    color: #fff;
    opacity: 0.7;
    white-space: nowrap;
  }

  h3 {
    color: #74b853;
  }
`;

export const TotalRow = styled(Row)`
  margin-top: 10px;
  margin-bottom: 10px;
`;
