import { FETCH_USUARIO_REGRA, FETCH_USUARIO_REGRA_SUCCESS, FETCH_USUARIO_REGRA_FAILURE } from './types';

export const fetchUsuarioRule = () => ({
  type: FETCH_USUARIO_REGRA,
});

export const fetchUsuarioRuleSuccess = (data: any[]) => ({
  type: FETCH_USUARIO_REGRA_SUCCESS,
  data
});

export const fetchUsuarioRuleFailure = () => ({
  type: FETCH_USUARIO_REGRA_FAILURE,
});
