import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { BarChartDashProps } from './interfaces';
import moment from 'moment';
import { adquirenteColors } from '../../../styles/globalInterfaces';
import { TooltipItemContainerStyle, TooltipStyle } from './styled';

function BarChartDash({ dataForChart, adquirenteNames, renderTooltipValue, typeXAxis, groupingType }: BarChartDashProps) {
  const CustomTooltip = ({ active, payload, label, groupingType }: any) => {
    const formattedDate = groupingType === 'day' ? moment(label).format('DD/MM/YYYY') : moment(label).format('MM/YYYY');
    if (active && payload && payload.length) {
      const totalValue = payload.reduce((acc: any, entry: any) => acc + entry.value, 0);
      return (
        <TooltipStyle>
          <p className="label">{formattedDate}</p>
          <p style={{ fontWeight: 'bold' }}>{`Total: ${renderTooltipValue(totalValue)}`}</p>
          <TooltipItemContainerStyle>
            {payload.map((entry: any, index: any) => (
              <p key={`item-${index}`} style={{ fontWeight: 'bold', color: (adquirenteColors as Record<string, string>)[entry.name] }}>
                {`${entry.name}: ${renderTooltipValue(entry.value)}`}
              </p>
            ))}
          </TooltipItemContainerStyle>
        </TooltipStyle>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        style={{ cursor: 'pointer' }}
        data={dataForChart}
        margin={{
          right: 15,
          left: 25,
          bottom: 25,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={typeXAxis}
          stroke="white"
          angle={-45}
          textAnchor="end"
          tick={{ fontSize: 12 }}
          tickFormatter={(value) => {
            if (groupingType === 'month') {
              return moment(value).format('MM/YY');
            } else if (groupingType === 'day') {
              return moment(value).format('DD/MM/YY');
            } else {
              return moment(value).format('YY');
            }
          }}
        />
        <YAxis stroke="white" tickFormatter={(value) => new Intl.NumberFormat('pt-BR').format(value)} />
        <Tooltip cursor={{ fill: '#525FA2' }} allowEscapeViewBox={{ x: false, y: false }} content={<CustomTooltip groupingType={groupingType} />} />
        {adquirenteNames.map((key: any, index) => (
          <Bar key={key} dataKey={key} stackId="a" fill={adquirenteColors[key as keyof typeof adquirenteColors] || '#252f64'} radius={25} style={{ cursor: 'pointer', opacity: 0.8 }} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarChartDash;
