import { 
    CLEAR_AGENDA_RECEBIMENTOS_DATA,
    FETCH_AGENDA_RECEBIMENTOS_CHART_DATA_FAILURE, FETCH_AGENDA_RECEBIMENTOS_CHART_DATA_SUCCESS, FETCH_AGENDA_RECEBIMENTOS_DATA, FETCH_AGENDA_RECEBIMENTOS_FAILURE, FETCH_AGENDA_RECEBIMENTOS_PDF, FETCH_AGENDA_RECEBIMENTOS_PDF_FAILURE, FETCH_AGENDA_RECEBIMENTOS_PDF_SUCCESS, FETCH_AGENDA_RECEBIMENTOS_SUCCESS, FETCH_AGENDA_RECEBIMENTOS_TOTALS, FETCH_AGENDA_RECEBIMENTOS_TOTALS_FAILURE, FETCH_AGENDA_RECEBIMENTOS_TOTALS_SUCCESS, FETCH_AGENDA_RECEBIMENTOS_XLS, FETCH_AGENDA_RECEBIMENTOS_XLS_FAILURE, FETCH_AGENDA_RECEBIMENTOS_XLS_SUCCESS } from "./types";


export const clearAgendaRecebimentosData = () => ({
    type: CLEAR_AGENDA_RECEBIMENTOS_DATA,
});

export const fetchAgendaRecebimentosTotalsSuccess = (data: any) => ({
    type: FETCH_AGENDA_RECEBIMENTOS_TOTALS_SUCCESS,
    payload: data,
});

export const fetchAgendaRecebimentosTotalsFailure = (error: any) => ({
    type: FETCH_AGENDA_RECEBIMENTOS_TOTALS_FAILURE,
    payload: error,
});

export const fetchAgendaRecebimentosTotals = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
    type: FETCH_AGENDA_RECEBIMENTOS_TOTALS,
    payload,
});

export const fetchAgendaRecebimentos = (payload: { company_id: number | number[]; start_date: any; end_date: any; page: number; pageSize: number; filters?: Record<string, any>; sortField: any; sortOrder: any }) => ({
    type: FETCH_AGENDA_RECEBIMENTOS_DATA,
    payload,
});

export const fetchAgendaRecebimentosSuccess = (data: any) => ({
    type: FETCH_AGENDA_RECEBIMENTOS_SUCCESS,
    payload: data,
});

export const fetchAgendaRecebimentosFailure = (error: any) => ({
    type: FETCH_AGENDA_RECEBIMENTOS_FAILURE,
    payload: error,
});

export const fetchAgendaRecebimentosChartDataSuccess = (data: any) => ({
    type: FETCH_AGENDA_RECEBIMENTOS_CHART_DATA_SUCCESS,
    payload: data,
  });

export const fetchAgendaRecebimentosChartDataFailure = (error: any) => ({
    type: FETCH_AGENDA_RECEBIMENTOS_CHART_DATA_FAILURE,
    payload: error,
});

export const fetchAgendaRecebimentosPdf = (payload: { company_id: number | number[]; start_date: string; end_date: string }) => ({
    type: FETCH_AGENDA_RECEBIMENTOS_PDF,
    payload,
  });
  
export const fetchAgendaRecebimentosPdfSuccess = () => ({
    type: FETCH_AGENDA_RECEBIMENTOS_PDF_SUCCESS,
});

export const fetchAgendaRecebimentosPdfFailure = (error: any) => ({
    type: FETCH_AGENDA_RECEBIMENTOS_PDF_FAILURE,
    payload: error,
});

export const fetchAgendaRecebimentosXls = (payload: { company_id: number | number[]; start_date: string; end_date: string }) => ({
    type: FETCH_AGENDA_RECEBIMENTOS_XLS,
    payload,
});

export const fetchAgendaRecebimentosXlsSuccess = () => ({
    type: FETCH_AGENDA_RECEBIMENTOS_XLS_SUCCESS,
});

export const fetchAgendaRecebimentosXlsFailure = (error: any) => ({
    type: FETCH_AGENDA_RECEBIMENTOS_XLS_FAILURE,
    payload: error,
});