import { Card } from 'antd';
import styled, { css, keyframes } from 'styled-components';
import backgroundImage from '../../../assets/global/background.svg';

const processingDataAnimation = keyframes`
  0%, 100% {
    filter: blur(5px);
    opacity: 0.5;
    color: #555;
  }
  50% {
    filter: blur(2px);
    opacity: 0.75;
    color: #ddd;
  }
`;

interface ValueStyledProps {
  loading: boolean;
}

export const ValueStyled = styled.p<ValueStyledProps>`
  font-size: clamp(0.8rem, 2vw + 1rem, 1.2rem);
  font-weight: 800;
  color: #fff;
  opacity: 0.8;
  animation: ${(props) =>
    props.loading
      ? css`
          ${processingDataAnimation} 3s ease-in-out infinite
        `
      : 'none'};
`;

export const StatCardStyled = styled.div`
  h3 {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0;
    font-size: clamp(0.6rem, 1.2vw + 0.5rem, 1rem);
    font-weight: 500;
    color: #fff;
    opacity: 0.7;
    white-space: nowrap;
  }
`;

export const CardStyled = styled(Card)`
  display: flex;
  max-height: 300px;
  margin-top: 10px;
  flex: 1;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #252f64;
  &:hover {
    background-color: #3c4780;
  }
  background-image: url(${backgroundImage});
  background-blend-mode: multiply;
  background-size: cover;
  background-position: center;
`;
