import { Reducer } from 'react';
import {
  CLEAR_AGENDA_RECEBIMENTOS_DATA,
  FETCH_AGENDA_RECEBIMENTOS_CHART_DATA_FAILURE,
  FETCH_AGENDA_RECEBIMENTOS_CHART_DATA_SUCCESS,
  FETCH_AGENDA_RECEBIMENTOS_DATA,
  FETCH_AGENDA_RECEBIMENTOS_FAILURE,
  FETCH_AGENDA_RECEBIMENTOS_PDF,
  FETCH_AGENDA_RECEBIMENTOS_PDF_FAILURE,
  FETCH_AGENDA_RECEBIMENTOS_PDF_SUCCESS,
  FETCH_AGENDA_RECEBIMENTOS_SUCCESS,
  FETCH_AGENDA_RECEBIMENTOS_TOTALS,
  FETCH_AGENDA_RECEBIMENTOS_TOTALS_FAILURE,
  FETCH_AGENDA_RECEBIMENTOS_TOTALS_SUCCESS,
  FETCH_AGENDA_RECEBIMENTOS_XLS,
  FETCH_AGENDA_RECEBIMENTOS_XLS_FAILURE,
  FETCH_AGENDA_RECEBIMENTOS_XLS_SUCCESS,
} from './types';

const agendaRecebimentosState = {
  data: [],
  agendaRecebimentosTotals: {},
  recebimentoChartData: [],
  loadingTotals: false,
  loading: false,
  loadingRecebimentoExport: false,
  error: null,
  errorTotals: null,
  sortField: null,
  sortOrder: null,
};

export const reducer: Reducer<any, any> = (state = agendaRecebimentosState, action: any) => {
  switch (action.type) {
    case CLEAR_AGENDA_RECEBIMENTOS_DATA:
      return { ...agendaRecebimentosState };
    case FETCH_AGENDA_RECEBIMENTOS_TOTALS:
      return { ...state, loadingTotals: true };
    case FETCH_AGENDA_RECEBIMENTOS_TOTALS_SUCCESS:
      return { ...state, loadingTotals: false, agendaRecebimentosTotals: action.payload || {} };
    case FETCH_AGENDA_RECEBIMENTOS_TOTALS_FAILURE:
      return { ...state, loadingTotals: false, errorTotals: action.payload };
    case FETCH_AGENDA_RECEBIMENTOS_CHART_DATA_SUCCESS:
      return { ...state, recebimentoChartData: action.payload || [] };
    case FETCH_AGENDA_RECEBIMENTOS_CHART_DATA_FAILURE:
      return { ...state, error: action.payload };
    case FETCH_AGENDA_RECEBIMENTOS_DATA:
      return { ...state, loading: true };
    case FETCH_AGENDA_RECEBIMENTOS_SUCCESS:
      return { ...state, loading: false, data: action.payload || [] };
    case FETCH_AGENDA_RECEBIMENTOS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_AGENDA_RECEBIMENTOS_PDF:
      return { ...state, loadingRecebimentoExport: true };
    case FETCH_AGENDA_RECEBIMENTOS_PDF_SUCCESS:
      return { ...state, loadingRecebimentoExport: false };
    case FETCH_AGENDA_RECEBIMENTOS_PDF_FAILURE:
      return { ...state, loadingRecebimentoExport: false };
    case FETCH_AGENDA_RECEBIMENTOS_XLS:
      return { ...state, loadingRecebimentoExport: true };
    case FETCH_AGENDA_RECEBIMENTOS_XLS_SUCCESS:
      return { ...state, loadingRecebimentoExport: false };
    case FETCH_AGENDA_RECEBIMENTOS_XLS_FAILURE:
      return { ...state, loadingRecebimentoExport: false };

    default:
      return state;
  }
};
