import {
  CLEAR_PAGAMENTO_DATA,
  FETCH_PAGAMENTO_AUDIT_ANALYTICS,
  FETCH_PAGAMENTO_AUDIT_ANALYTICS_SUCCESS,
  FETCH_PAGAMENTO_AUDIT_ANALYTICS_FAILURE,
  FETCH_PAGAMENTO_CHART_DATA,
  FETCH_PAGAMENTO_CHART_DATA_FAILURE,
  FETCH_PAGAMENTO_CHART_DATA_SUCCESS,
  FETCH_PAGAMENTO_DATA,
  FETCH_PAGAMENTO_FAILURE,
  FETCH_PAGAMENTO_PDF,
  FETCH_PAGAMENTO_PDF_FAILURE,
  FETCH_PAGAMENTO_PDF_SUCCESS,
  FETCH_PAGAMENTO_SUCCESS,
  FETCH_PAGAMENTO_TOTALS,
  FETCH_PAGAMENTO_TOTALS_FAILURE,
  FETCH_PAGAMENTO_TOTALS_SUCCESS,
  FETCH_PAGAMENTO_XLS,
  FETCH_PAGAMENTO_XLS_FAILURE,
  FETCH_PAGAMENTO_XLS_SUCCESS,
  FETCH_PAGAMENTO_ANALYTICS,
  FETCH_PAGAMENTO_ANALYTICS_SUCCESS,
  FETCH_PAGAMENTO_ANALYTICS_FAILURE,
  FETCH_PAGAMENTO_AUDITADO_PDF,
  FETCH_PAGAMENTO_AUDITADO_PDF_SUCCESS,
  FETCH_PAGAMENTO_AUDITADO_PDF_FAILURE,
  FETCH_PAGAMENTO_AUDITADO_XLS,
  FETCH_PAGAMENTO_AUDITADO_XLS_SUCCESS,
  FETCH_PAGAMENTO_AUDITADO_XLS_FAILURE,
} from './types';

export const clearPagamentoData = () => ({
  type: CLEAR_PAGAMENTO_DATA,
});

export const fetchPagamentoAuditAnalytics = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
  type: FETCH_PAGAMENTO_AUDIT_ANALYTICS,
  payload,
});

export const fetchPagamentoAuditAnalyticsSuccess = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
  type: FETCH_PAGAMENTO_AUDIT_ANALYTICS_SUCCESS,
  payload,
});

export const fetchPagamentoAuditAnalyticsFailure = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
  type: FETCH_PAGAMENTO_AUDIT_ANALYTICS_FAILURE,
  payload,
});

export const fetchPagamentoAnalytics = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
  type: FETCH_PAGAMENTO_ANALYTICS,
  payload,
});

export const fetchPagamentoAnalyticsSuccess = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
  type: FETCH_PAGAMENTO_ANALYTICS_SUCCESS,
  payload,
});

export const fetchPagamentoAnalyticsFailture = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
  type: FETCH_PAGAMENTO_ANALYTICS_FAILURE,
  payload,
});

export const fetchPagamentoChartData = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
  type: FETCH_PAGAMENTO_CHART_DATA,
  payload,
});

export const fetchPagamentoChartDataSuccess = (data: any) => ({
  type: FETCH_PAGAMENTO_CHART_DATA_SUCCESS,
  payload: data,
});

export const fetchPagamentoChartDataFailure = (error: any) => ({
  type: FETCH_PAGAMENTO_CHART_DATA_FAILURE,
  payload: error,
});

export const fetchPagamentoTotals = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
  type: FETCH_PAGAMENTO_TOTALS,
  payload,
});

export const fetchPagamentoTotalsSuccess = (data: any) => ({
  type: FETCH_PAGAMENTO_TOTALS_SUCCESS,
  payload: data,
});

export const fetchPagamentoTotalsFailure = (error: any) => ({
  type: FETCH_PAGAMENTO_TOTALS_FAILURE,
  payload: error,
});

export const fetchPagamento = (payload: { company_id: number | number[]; start_date: any; end_date: any; page: number; pageSize: number; filters?: Record<string, any>; sortField: any; sortOrder: any }) => ({
  type: FETCH_PAGAMENTO_DATA,
  payload,
});

export const fetchPagamentoSuccess = (data: any) => ({
  type: FETCH_PAGAMENTO_SUCCESS,
  payload: data,
});

export const fetchPagamentoFailure = (error: any) => ({
  type: FETCH_PAGAMENTO_FAILURE,
  payload: error,
});

export const fetchPagamentoPdf = (payload: { company_id: number | number[]; start_date: string; end_date: string }) => ({
  type: FETCH_PAGAMENTO_PDF,
  payload,
});

export const fetchPagamentoPdfSuccess = () => ({
  type: FETCH_PAGAMENTO_PDF_SUCCESS,
});

export const fetchPagamentoPdfFailure = (error: any) => ({
  type: FETCH_PAGAMENTO_PDF_FAILURE,
  payload: error,
});

export const fetchPagamentoXls = (payload: { company_id: number | number[]; start_date: string; end_date: string }) => ({
  type: FETCH_PAGAMENTO_XLS,
  payload,
});

export const fetchPagamentoXlsSuccess = () => ({
  type: FETCH_PAGAMENTO_XLS_SUCCESS,
});

export const fetchPagamentoXlsFailure = (error: any) => ({
  type: FETCH_PAGAMENTO_XLS_FAILURE,
  payload: error,
});

export const fetchPagamentoAuditadoPdf = (payload: {}) => ({
  type: FETCH_PAGAMENTO_AUDITADO_PDF,
  payload,
});

export const fetchPagamentoAuditadoPdfSuccess = () => ({
  type: FETCH_PAGAMENTO_AUDITADO_PDF_SUCCESS,
});

export const fetchPagamentoAuditadoPdfFailure = (error: any) => ({
  type: FETCH_PAGAMENTO_AUDITADO_PDF_FAILURE,
  payload: error,
});

export const fetchPagamentoAuditadoXls = (payload: {}) => ({
  type: FETCH_PAGAMENTO_AUDITADO_XLS,
  payload,
});

export const fetchPagamentoAuditadoXlsSuccess = (data: any) => ({
  type: FETCH_PAGAMENTO_AUDITADO_XLS_SUCCESS,
  payload: data,
});

export const fetchPagamentoAuditadoXlsFailure = (error: any) => ({
  type: FETCH_PAGAMENTO_AUDITADO_XLS_FAILURE,
  payload: error,
});