import { FETCH_BANCO_DATA, FETCH_BANCO_SUCCESS, FETCH_BANCO_FAILURE } from './types';

export const fetchBanco = () => ({
  type: FETCH_BANCO_DATA,
});

export const fetchBancoSuccess = (data: any[]) => ({
  type: FETCH_BANCO_SUCCESS,
  data,
});

export const fetchBancoFailure = (error: any) => ({
  type: FETCH_BANCO_FAILURE,
  payload: error,
});
