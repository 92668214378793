import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import LoginScreen from '../screens/Login';
import RecoverPassword from '../screens/RecoverPassword';
import DashboardScreen from '../screens/DashboardScreen';
import PagamentoScreen from '../screens/PagamentoScreen';
import AnaliseVendaScreen from '../screens/AnaliseVendaScreen';
import VendaScreen from '../screens/VendaScreen';
import ResetPassword from '../screens/ResetPassword';
import LandingPage from '../screens/LandingPage';
import GestaoTaxaScreen from '../screens/GestaoTaxaScreen';
import AnalisePagamentoScreen from '../screens/AnalisePagamentoScreen';
import AgendaRecebimentosScreen from '../screens/AgendaRecebimentos';
import PagamentoConsolidadoScreen from '../screens/PagamentoConsolidado';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/login" component={LoginScreen} />
      <Route exact path="/recover" component={RecoverPassword} />
      <Route exact path="/reset" component={ResetPassword} />
      <PrivateRoute exact path="/dashboard" component={DashboardScreen} />
      <PrivateRoute exact path="/venda" component={VendaScreen} />
      <PrivateRoute exact path="/pagamento" component={PagamentoScreen} />
      <PrivateRoute exact path="/analise-vendas" component={AnaliseVendaScreen} />
      <PrivateRoute exact path="/analise-pagamentos" component={AnalisePagamentoScreen} />
      <PrivateRoute exact path="/gestao-taxa" component={GestaoTaxaScreen} />
      <PrivateRoute exact path="/agenda-recebimentos" component={AgendaRecebimentosScreen} />
      <PrivateRoute exact path="/pagamento-consolidado" component={PagamentoConsolidadoScreen} />
      <Route exact path="*" component={LoginScreen} />
    </Switch>
  );
};

export default Routes;
