export const CLEAR_PAGAMENTO_DATA = 'CLEAR_PAGAMENTO_DATA';
export const FETCH_PAGAMENTO_AUDIT_ANALYTICS = 'FETCH_PAGAMENTO_AUDIT_ANALYTICS';
export const FETCH_PAGAMENTO_AUDIT_ANALYTICS_SUCCESS = 'FETCH_PAGAMENTO_AUDIT_ANALYTICS_SUCCESS';
export const FETCH_PAGAMENTO_AUDIT_ANALYTICS_FAILURE = 'FETCH_PAGAMENTO_AUDIT_ANALYTICS_FAILURE';
export const FETCH_PAGAMENTO_ANALYTICS = 'FETCH_PAGAMENTO_ANALYTICS';
export const FETCH_PAGAMENTO_ANALYTICS_SUCCESS = 'FETCH_PAGAMENTO_ANALYTICS_SUCCESS';
export const FETCH_PAGAMENTO_ANALYTICS_FAILURE = 'FETCH_PAGAMENTO_ANALYTICS_FAILURE';
export const FETCH_PAGAMENTO_CHART_DATA = 'FETCH_PAGAMENTO_CHART_DATA';
export const FETCH_PAGAMENTO_CHART_DATA_SUCCESS = 'FETCH_PAGAMENTO_CHART_DATA_SUCCESS';
export const FETCH_PAGAMENTO_CHART_DATA_FAILURE = 'FETCH_PAGAMENTO_CHART_DATA_FAILURE';
export const FETCH_PAGAMENTO_TOTALS = 'FETCH_PAGAMENTO_TOTALS';
export const FETCH_PAGAMENTO_TOTALS_SUCCESS = 'FETCH_PAGAMENTO_TOTALS_SUCCESS';
export const FETCH_PAGAMENTO_TOTALS_FAILURE = 'FETCH_PAGAMENTO_TOTALS_FAILURE';
export const FETCH_PAGAMENTO_DATA = 'FETCH_PAGAMENTO_DATA';
export const FETCH_PAGAMENTO_SUCCESS = 'FETCH_PAGAMENTO_SUCCESS';
export const FETCH_PAGAMENTO_FAILURE = 'FETCH_PAGAMENTO_FAILURE';
export const FETCH_PAGAMENTO_PDF = 'FETCH_PAGAMENTO_PDF';
export const FETCH_PAGAMENTO_PDF_SUCCESS = 'FETCH_PAGAMENTO_PDF_SUCCESS';
export const FETCH_PAGAMENTO_PDF_FAILURE = 'FETCH_PAGAMENTO_PDF_FAILURE';
export const FETCH_PAGAMENTO_XLS = 'FETCH_PAGAMENTO_XLS';
export const FETCH_PAGAMENTO_XLS_SUCCESS = 'FETCH_PAGAMENTO_XLS_SUCCESS';
export const FETCH_PAGAMENTO_XLS_FAILURE = 'FETCH_PAGAMENTO_XLS_FAILURE';
export const FETCH_PAGAMENTO_AUDITADO_PDF = 'FETCH_PAGAMENTO_AUDITADO_PDF';
export const FETCH_PAGAMENTO_AUDITADO_PDF_SUCCESS = 'FETCH_PAGAMENTO_AUDITADO_PDF_SUCCESS';
export const FETCH_PAGAMENTO_AUDITADO_PDF_FAILURE = 'FETCH_PAGAMENTO_AUDITADO_PDF_FAILURE';
export const FETCH_PAGAMENTO_AUDITADO_XLS = 'FETCH_PAGAMENTO_AUDITADO_XLS';
export const FETCH_PAGAMENTO_AUDITADO_XLS_SUCCESS = 'FETCH_PAGAMENTO_AUDITADO_XLS_SUCCESS';
export const FETCH_PAGAMENTO_AUDITADO_XLS_FAILURE = 'FETCH_PAGAMENTO_AUDITADO_XLS_FAILURE';
