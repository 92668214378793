import { Reducer } from 'redux';
import { CLEAR_EMPRESA_DATA, FETCH_EMPRESA_FAILURE, FETCH_EMPRESA_SUCCESS, UPDATE_EMPRESA_ID } from './types';

export const reducer: Reducer<any, any> = (state = {}, action: any) => {
  switch (action.type) {
    case CLEAR_EMPRESA_DATA:
      return { ...{} };
    case FETCH_EMPRESA_SUCCESS:
      return {
        ...state,
        data: action.data.slice(),
      };
    case FETCH_EMPRESA_FAILURE:
      return { ...state, error: action.payload };
    case UPDATE_EMPRESA_ID:
      return {
        ...state,
        companyID: action.payload,
      };
    default:
      return state;
  }
};
