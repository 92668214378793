import { Reducer } from 'redux';
import {
  CLEAR_PAGAMENTO_DATA,
  FETCH_PAGAMENTO_AUDIT_ANALYTICS,
  FETCH_PAGAMENTO_AUDIT_ANALYTICS_SUCCESS,
  FETCH_PAGAMENTO_AUDIT_ANALYTICS_FAILURE,
  FETCH_PAGAMENTO_CHART_DATA_FAILURE,
  FETCH_PAGAMENTO_CHART_DATA_SUCCESS,
  FETCH_PAGAMENTO_DATA,
  FETCH_PAGAMENTO_FAILURE,
  FETCH_PAGAMENTO_PDF,
  FETCH_PAGAMENTO_PDF_FAILURE,
  FETCH_PAGAMENTO_PDF_SUCCESS,
  FETCH_PAGAMENTO_SUCCESS,
  FETCH_PAGAMENTO_TOTALS,
  FETCH_PAGAMENTO_TOTALS_FAILURE,
  FETCH_PAGAMENTO_TOTALS_SUCCESS,
  FETCH_PAGAMENTO_XLS,
  FETCH_PAGAMENTO_XLS_FAILURE,
  FETCH_PAGAMENTO_XLS_SUCCESS,
  FETCH_PAGAMENTO_ANALYTICS,
  FETCH_PAGAMENTO_ANALYTICS_SUCCESS,
  FETCH_PAGAMENTO_ANALYTICS_FAILURE,
  FETCH_PAGAMENTO_AUDITADO_PDF,
  FETCH_PAGAMENTO_AUDITADO_PDF_SUCCESS,
  FETCH_PAGAMENTO_AUDITADO_PDF_FAILURE,
  FETCH_PAGAMENTO_AUDITADO_XLS,
  FETCH_PAGAMENTO_AUDITADO_XLS_SUCCESS,
  FETCH_PAGAMENTO_AUDITADO_XLS_FAILURE,
} from './types';

const pagamentoState = {
  data: [],
  pagamentoTotals: {},
  pagamentoChartData: [],
  pagamentoAnalytics: [],
  pagamentoAuditAnalytics: [],
  loadingTotals: false,
  loading: false,
  loadingAnalytics: false,
  loadingPagamentoExport: false,
  loadingPagamentoExportAudit: false,
  error: null,
  errorTotals: null,
  sortField: null,
  sortOrder: null,
};

export const reducer: Reducer<any, any> = (state = pagamentoState, action: any) => {
  switch (action.type) {
    case CLEAR_PAGAMENTO_DATA:
      return { ...pagamentoState };
    case FETCH_PAGAMENTO_AUDIT_ANALYTICS:
      return { ...state };
    case FETCH_PAGAMENTO_AUDIT_ANALYTICS_SUCCESS:
      return { ...state, pagamentoAuditAnalytics: action.payload || {} };
    case FETCH_PAGAMENTO_AUDIT_ANALYTICS_FAILURE:
      return { ...state, error: action.payload };
    case FETCH_PAGAMENTO_ANALYTICS:
      return { ...state, loadingAnalytics: true };
    case FETCH_PAGAMENTO_ANALYTICS_SUCCESS:
      return { ...state, loadingAnalytics: false, pagamentoAnalytics: action.payload || {} };
    case FETCH_PAGAMENTO_ANALYTICS_FAILURE:
      return { ...state, loadingAnalytics: false, error: action.payload };
    case FETCH_PAGAMENTO_CHART_DATA_SUCCESS:
      return { ...state, pagamentoChartData: action.payload };
    case FETCH_PAGAMENTO_CHART_DATA_FAILURE:
      return { ...state, error: action.payload };
    case FETCH_PAGAMENTO_TOTALS:
      return { ...state, loadingTotals: true };
    case FETCH_PAGAMENTO_TOTALS_SUCCESS:
      return { ...state, loadingTotals: false, pagamentoTotals: action.payload };
    case FETCH_PAGAMENTO_TOTALS_FAILURE:
      return { ...state, loadingTotals: false, errorTotals: action.payload };
    case FETCH_PAGAMENTO_DATA:
      return { ...state, loading: true };
    case FETCH_PAGAMENTO_SUCCESS:
      return { ...state, loading: false, data: action.payload || [] };
    case FETCH_PAGAMENTO_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_PAGAMENTO_PDF:
      return { ...state, loadingPagamentoExport: true };
    case FETCH_PAGAMENTO_PDF_SUCCESS:
      return { ...state, loadingPagamentoExport: false };
    case FETCH_PAGAMENTO_PDF_FAILURE:
      return { ...state, loadingPagamentoExport: false, error: action.payload };
    case FETCH_PAGAMENTO_XLS:
      return { ...state, loadingPagamentoExport: true };
    case FETCH_PAGAMENTO_XLS_SUCCESS:
      return { ...state, loadingPagamentoExport: false };
    case FETCH_PAGAMENTO_XLS_FAILURE:
      return { ...state, loadingPagamentoExport: false, error: action.payload };
    case FETCH_PAGAMENTO_AUDITADO_PDF:
      return { ...state, loadingPagamentoExportAudit: true };
    case FETCH_PAGAMENTO_AUDITADO_PDF_SUCCESS:
      return { ...state, loadingPagamentoExportAudit: false };
    case FETCH_PAGAMENTO_AUDITADO_PDF_FAILURE:
      return { ...state, loadingPagamentoExportAudit: false, error: action.payload };
    case FETCH_PAGAMENTO_AUDITADO_XLS:
      return { ...state, loadingPagamentoExportAudit: true };
    case FETCH_PAGAMENTO_AUDITADO_XLS_SUCCESS:
      return { ...state, loadingPagamentoExportAudit: false };
    case FETCH_PAGAMENTO_AUDITADO_XLS_FAILURE:
      return { ...state, loadingPagamentoExportAudit: false, error: action.payload };
    default:
      return state;
  }
};
