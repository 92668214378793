import { Reducer } from 'redux';
import { FETCH_MODALIDADE_SUCCESS, FETCH_MODALIDADE_FAILURE } from './types';

export const reducer: Reducer<any, any> = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_MODALIDADE_SUCCESS:
      return {
        ...state,
        data: action.data.slice(),
      };
    case FETCH_MODALIDADE_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
