import React, { useState } from 'react';
import { Form, Modal, Input } from 'antd';
import { LoginProposal, ModalDisplay} from './styled';
import { proposal } from '../../../store/states/usuario/actions';
import { useDispatch } from 'react-redux';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalProposal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    nome: '',
    telefone: '',
    email: '',
  });

  const handleFieldChange = (fieldName: string, value: string) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const handleClose = () => {
    setFormData({
      nome: '',
      telefone: '',
      email: '',
    });
    onClose();
  };

  const enviarSolicitacaoContato = async () => {
    dispatch(proposal(formData));
    onClose();
  };

  return (
    <ModalDisplay isOpen={isOpen}>
      <Modal
        visible={isOpen}
        onCancel={handleClose}
        footer={null}
        width={600}
        centered
        closable={false}
      >
        <Form layout="vertical">

          <div
            style={{
              color: '#22326E',
              fontFamily: 'Poppins',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '33.5px',
              margin: '0 auto',
              textAlign: 'center',
            }}
          >
            Deixe-nos conhecê-lo melhor! <br />
            Preencha o formulário abaixo <br />
            e embarque na jornada da <br />
            excelência conosco.
          </div>

          <Form.Item label="Nome" wrapperCol={{ span: 24 }} style={{ textAlign: 'center' }}>
            <Input
              value={formData.nome}
              onChange={(e) => handleFieldChange('nome', e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Telefone">
            <Input
              value={formData.telefone}
              onChange={(e) => handleFieldChange('telefone', e.target.value)}
            />
          </Form.Item>
          <Form.Item label="E-mail">
            <Input
              value={formData.email}
              onChange={(e) => handleFieldChange('email', e.target.value)}
            />
          </Form.Item>
          <LoginProposal
            style={{ width: '100%', marginTop: '5%' }}
            onClick={enviarSolicitacaoContato}
            type="primary"
            shape="round"
          >
            Enviar Solicitação
          </LoginProposal>
        </Form>
      </Modal>
    </ModalDisplay>
  );
};

export default ModalProposal;
