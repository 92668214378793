import { RocketOutlined } from '@ant-design/icons';
import { Card, Col } from 'antd';
import { useEffect, useState } from 'react';

function MessageHeader({ textMessage }: { textMessage: string }) {
  const [typingText, setTypingText] = useState('');
  const [blink, setBlink] = useState(true);

  useEffect(() => {
    let currentIndex = 0;
    const typeText = () => {
      if (currentIndex <= textMessage.length) {
        setTypingText(textMessage.slice(0, currentIndex));
        currentIndex++;
        setTimeout(typeText, 50);
      } else {
        const blinkInterval = setInterval(() => {
          setBlink((prev) => !prev);
        }, 500);
        return () => clearInterval(blinkInterval);
      }
    };
    typeText();
    return () => clearTimeout(typingText);
  }, [textMessage]);

  return (
    <Col span={24}>
      <Card style={{ cursor: 'pointer', marginBottom: '8px', border: 'none', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
        <RocketOutlined style={{ color: '#1890ff' }} /> {typingText}
        <span style={{ opacity: blink ? 1 : 0 }}>|</span>
      </Card>
    </Col>
  );
}

export default MessageHeader;
