import { Form } from 'antd';
import backgroundRight from '../../assets/login/background-right.svg';
import styled from 'styled-components';

export const LoginWrapper = styled.div`
  display: flex;
  height: 100vh;
  @media (max-width: 1680px) {
    flex-direction: column;
  }
`;

export const FormSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1680px) {
    order: 2;
  }
`;

export const ImageSide = styled.div`
  flex: 1.7;
  background-image: url(${backgroundRight});
  background-size: cover;
  background-position: center;
  @media (max-width: 1680px) {
    display: none;
  }
`;

export const StyledForm = styled(Form)`
  max-width: 450px;
  width: 100%;
  @media (max-width: 390px) {
    max-width: 350px;
  }
`;
