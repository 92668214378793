import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/states/auth/actions';
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import logoCard from '../../assets/login/logo-card-union.svg';
import { Link } from 'react-router-dom';
import { getSubdomain } from '../../utils/validateCustomer';
import { RootState } from '../../store/types';
import { FormSide, ImageSide, LoginWrapper, StyledForm } from './styled';
import { FaInstagram, FaLinkedin, FaWhatsapp } from 'react-icons/fa';

interface FormValues {
  username: string;
  password: string;
  remember: boolean;
}

const LoginScreen: React.FC = () => {
  const dispatch = useDispatch();
  const authLoading = useSelector((state: RootState) => state.auth.loading);
  const [form] = Form.useForm<unknown>();

  const onFinish = (values: any) => {
    const subdomain = getSubdomain();
    dispatch(login(values.username, values.password, subdomain));
  };

  return (
    <LoginWrapper>
      <FormSide>
        <StyledForm style={{ background: '#fff', padding: 35, borderRadius: 12 }} form={form} initialValues={{ remember: true }} onFinish={onFinish}>
          <div style={{ textAlign: 'left', marginBottom: 100 }}>
            <img src={logoCard} alt="Logo" style={{ width: 250 }} />
          </div>
          <Form.Item name="username" rules={[{ required: true, message: 'Por favor, insira seu usuário!' }]}>
            <Input disabled={authLoading} placeholder="Usuário" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: 'Por favor, insira sua senha!' }]}>
            <Input.Password disabled={authLoading} placeholder="Senha" />
          </Form.Item>
          <Form.Item>
            <Row justify="end">
              <Col>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox style={{ fontFamily: 'Poppins' }}>Mantenha-me conectado</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={authLoading} style={{ width: '100%', backgroundColor: '#252f64' }}>
              {!authLoading ? 'Entrar' : 'Entrando, aguarde...'}
            </Button>
          </Form.Item>
          <Form.Item>
            <Row justify="center" style={{ fontFamily: 'Poppins' }}>
              <Link to="/recover">Esqueceu sua senha? Clique aqui</Link>
            </Row>
          </Form.Item>
          <div style={{ marginTop: '50px', textAlign: 'center' }}>
            <a href="https://www.linkedin.com/company/finly-tech/mycompany/" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px', fontSize: '24px' }}>
              <FaLinkedin />
            </a>
            <a href="https://wa.me/5531997908777" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px', fontSize: '24px' }}>
              <FaWhatsapp />
            </a>
            <a href="https://www.instagram.com/finlytech/" target="_blank" rel="noopener noreferrer" style={{ margin: '0 10px', fontSize: '24px' }}>
              <FaInstagram />
            </a>
          </div>
        </StyledForm>
      </FormSide>
      <ImageSide />
    </LoginWrapper>
  );
};

export default LoginScreen;
