import { takeLatest, call, put } from 'redux-saga/effects';
import { bandeiraApi } from '../../target-api/api';
import { toast } from 'react-toastify';
import { fetchBandeiraSuccess } from './actions';
import { FETCH_BANDEIRA_DATA } from './types';

export function* fetchBandeiraSaga() {
  try {
    const data: any[] = yield call(bandeiraApi);
    if (data !== undefined) {
      yield put(fetchBandeiraSuccess(data));
    } else {
      toast.warn('Falha ao retornar lista de bandeiras!');
    }
  } catch (error: any) {
    toast.error(error);
  }
}

export function* watchBandeiraSaga() {
  yield takeLatest(FETCH_BANDEIRA_DATA, fetchBandeiraSaga);
}
