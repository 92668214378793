import { createGlobalStyle } from 'styled-components';

// greenCard: '#C9E437',
// blueCard: '#252f64',

export const GlobalStyle = createGlobalStyle`
  .ant-form-item-label label,
  .ant-input,
  .ant-typography,
  .ant-table,
  .ant-button,
  .ant-select,
  .ant-card,
  .ant-message,
  .ant-message-notice-content,
  .ant-menu,
  .ant-collapse,
  .ant-btn {
    font-family: 'Poppins', sans-serif !important;
  }

  iframe#webpack-dev-server-client-overlay{display:none!important}
  
  html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
  }
`;
