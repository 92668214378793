import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ConfirmationDialog = styled.div`
  position: relative;
  background-color: #fff;
  padding: 40px 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  text-align: center;
  border-radius: 12px;
  width: 500px;
  max-width: 500px;
  transition: transform 0.3s ease-out;
  transform: scale(1);

  @media (max-width: 800px) {
    font-size: 12px;
  }

  @media (max-width: 390px) {
    /* Iphone 12 Pro */
  }
`;

export const ConfirmationText = styled.p`
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: 500;
  color: #333;

  @media (max-width: 800px) {
    font-size: 16px;
  }

  @media (max-width: 390px) {
    /* Iphone 12 Pro */
    font-size: 14px;
  }
`;

export const ConfirmationButton = styled.button`
  color: #fff;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  margin-right: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  width: 150px;

  &:hover {
    background-color: #3949a1;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 800px) {
    font-size: 16px;
    margin: 10px 0;
    width: 100%;
  }

  @media (max-width: 390px) {
    /* Iphone 12 Pro */
    font-size: 14px;
  }
`;

export const CancelButton = styled.button`
  background-color: #e0e0e0;
  color: #333;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  width: 150px;

  &:hover {
    background-color: #bdbdbd;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 800px) {
    font-size: 16px;
    margin: 10px 0;
    width: 100%;
  }

  @media (max-width: 390px) {
    /* Iphone 12 Pro */
    font-size: 14px;
  }
`;
