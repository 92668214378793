import { takeLatest, call, put, all } from 'redux-saga/effects';
import { vendaAnalyticsApi, vendaApi, vendaPdfApi, vendaAuditAnalyticsApi, vendaAuditadaApiPdf, vendaChartDataApi, vendaTotalsApi, vendaXlsApi, vendaAuditadaApiXls } from '../../target-api/api';
import {
  fetchVendaSuccess,
  fetchVendaFailure,
  fetchVendaPdfSuccess,
  fetchVendaPdfFailure,
  fetchVendaAuditadaPdfSuccess,
  fetchVendaTotalsSuccess,
  fetchVendaChartDataSuccess,
  fetchVendaAnalyticsSuccess,
  fetchVendaAuditAnalyticsSuccess,
  fetchVendaAnalyticsFailture,
  fetchVendaXlsSuccess,
  fetchVendaTotalsFailure,
  fetchVendaAuditadaPdfFailure,
  fetchVendaAuditadaXlsFailure,
  fetchVendaAuditadaXlsSuccess,
} from './actions';
import { FETCH_VENDA_ANALYTICS, FETCH_VENDA_AUDITADA_PDF, FETCH_VENDA_AUDITADA_XLS, FETCH_VENDA_DATA, FETCH_VENDA_PDF, FETCH_VENDA_TOTALS, FETCH_VENDA_XLS } from './types';
import { message } from 'antd';

export function* fetchVendaAnalytics(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [vendaAnalyticsResponse, totalsAuditAnalyticsResponse] = yield all([call(vendaAnalyticsApi, company_id, start_date, end_date), call(vendaAuditAnalyticsApi, company_id, start_date, end_date)]);
    if (vendaAnalyticsResponse && vendaAnalyticsResponse.length <= 0) {
      message.warning('Nenhum dado de analytics de venda encontrado!');
      yield put(fetchVendaAnalyticsSuccess({ company_id: [], start_date: '', end_date: '' }));
    } else {
      yield put(fetchVendaAnalyticsSuccess(vendaAnalyticsResponse));
    }
    if (totalsAuditAnalyticsResponse && totalsAuditAnalyticsResponse.length <= 0) {
      message.warning('Nenhum total de analytics de venda auditada encontrado!');
    } else {
      yield put(fetchVendaAuditAnalyticsSuccess(totalsAuditAnalyticsResponse));
    }
  } catch (error: any) {
    yield put(fetchVendaAnalyticsFailture(error.message));
  }
}

export function* fetchVendaTotalsSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [totalsVendaResponse, vendaChartDataResponse] = yield all([call(vendaTotalsApi, company_id, start_date, end_date), call(vendaChartDataApi, company_id, start_date, end_date)]);
    if (totalsVendaResponse && totalsVendaResponse.length <= 0) {
      message.warning('Nenhum total de venda encontrado!');
    } else {
      yield put(fetchVendaTotalsSuccess(totalsVendaResponse));
    }
    if (vendaChartDataResponse && vendaChartDataResponse.length <= 0) {
      message.warning('Nenhum dado do dashboard de venda encontrado!');
    } else {
      yield put(fetchVendaChartDataSuccess(vendaChartDataResponse));
    }
  } catch (error: any) {
    yield put(fetchVendaTotalsFailure(error.message));
  }
}

export function* fetchVendaSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date, page, pageSize, filters, sortField, sortOrder } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [vendaResponse] = yield all([call(vendaApi, company_id, start_date, end_date, page, pageSize, filters, sortField, sortOrder)]);
    if (vendaResponse && vendaResponse.length <= 0) {
      message.warning('Nenhuma venda encontrada!');
      yield put(fetchVendaSuccess([]));
    } else {
      yield put(fetchVendaSuccess(vendaResponse));
    }
  } catch (error: any) {
    yield put(fetchVendaFailure(error.message));
  }
}

export function* fetchVendaPdfSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [vendaPdfResponse] = yield call(vendaPdfApi, company_id, start_date, end_date);
    if (vendaPdfResponse && vendaPdfResponse.length > 0) {
      yield put(fetchVendaPdfSuccess());
    } else {
      message.warning('Falha ao exportar lista de vendas em PDF!');
    }
  } catch (error: any) {
    yield put(fetchVendaPdfFailure(error.message));
  }
}

export function* fetchVendaXlsSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [vendaXlsResponse] = yield call(vendaXlsApi, company_id, start_date, end_date);
    if (vendaXlsResponse && vendaXlsResponse.length > 0) {
      yield put(fetchVendaXlsSuccess());
    } else {
      message.warning('Falha ao exportar lista de vendas em XLS!');
    }
  } catch (error: any) {
    yield put(fetchVendaPdfFailure(error.message));
  }
}

export function* fetchVendaAuditadaPdfSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [response] = yield call(vendaAuditadaApiPdf, company_id, start_date, end_date);
    if (response && response.length > 0) {
      yield put(fetchVendaAuditadaPdfSuccess());
    } else {
      message.warning('Falha ao exportar lista de vendas auditadas!');
    }
  } catch (error: any) {
    yield put(fetchVendaAuditadaPdfFailure(error.message));
  }
}

export function* fetchVendaAuditadaXlsSaga(action: any): Generator<any, void, any> {
  try {
    let { company_id, start_date, end_date } = action.payload;
    if (Array.isArray(company_id) && company_id.length === 1) {
      company_id = company_id[0];
    }
    const [response] = yield call(vendaAuditadaApiXls, company_id, start_date, end_date);
    if (response && response.length > 0) {
      yield put(fetchVendaAuditadaXlsSuccess());
    } else {
      message.warning('Falha ao exportar lista de vendas em XLSX!');
    }
  } catch (error: any) {
    yield put(fetchVendaAuditadaXlsFailure(error.message));
  }
}

export function* watchVendaSaga() {
  yield takeLatest(FETCH_VENDA_TOTALS, fetchVendaTotalsSaga);
  yield takeLatest(FETCH_VENDA_DATA, fetchVendaSaga);
  yield takeLatest(FETCH_VENDA_ANALYTICS, fetchVendaAnalytics);
  yield takeLatest(FETCH_VENDA_PDF, fetchVendaPdfSaga);
  yield takeLatest(FETCH_VENDA_XLS, fetchVendaXlsSaga);
  yield takeLatest(FETCH_VENDA_AUDITADA_PDF, fetchVendaAuditadaPdfSaga);
  yield takeLatest(FETCH_VENDA_AUDITADA_XLS, fetchVendaAuditadaXlsSaga);
}
