import dashboardIcon from '../../assets/sidebar/dashboard-icon.svg';
import analyticsIcon from '../../assets/sidebar/analytics-icon.svg';
import ajustesFinanceirosIcon from '../../assets/sidebar/ajustes-financeiros.svg';
import conciliacaoFinanceiraIcon from '../../assets/sidebar/conciliacao-financeira-icon.svg';
import rate_icon from '../../assets/sidebar/icon-gestao-taxas.svg';

export const DashboardIcon = () => (
  <img src={dashboardIcon} alt="Dashboard" style={{ width: '25px', marginRight: '10px' }} />
);

export const AnalyticsIcon = () => (
  <img src={analyticsIcon} alt="Analytics" style={{ width: '25px', marginRight: '10px' }} />
);

export const FinancialConferenceIcon = () => (
  <img
    src={conciliacaoFinanceiraIcon}
    alt="Financial Conference"
    style={{ width: '25px', marginRight: '10px' }}
  />
);

export const AuditSalesIcon = () => (
  <img
    src={ajustesFinanceirosIcon}
    alt="Audit Sales"
    style={{ width: '25px', marginRight: '10px' }}
  />
);

export const RateIcon = () => (
  <img src={rate_icon} alt="Audit Sales" style={{ width: '25px', marginRight: '10px' }} />
);
