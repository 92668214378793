import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { Col, Row, message, Skeleton } from 'antd';
import { Container } from '../../styles/GlobalStyles';
import { SafetyCertificateOutlined, QuestionCircleOutlined, BugOutlined, CaretUpOutlined, CaretDownOutlined, FireOutlined, FieldNumberOutlined } from '@ant-design/icons';
import TableComponent from '../../components/TablePagination';
import { StatCard } from '../../components/Charts/StatCard';
import { fetchPagamentoAuditadoPdf, fetchPagamentoAuditadoXls } from '../../store/states/pagamento/actions';
import AnimatedBanner from '../../components/AnimatedBanner';
import { mapFiltersPagamentoForApi } from '../../utils/validateFilter';
import { DropDownContent } from '../../components/DropDownContent';
import { textHeaderAnalytics } from './interfaces/all';
import MessageHeader from '../../components/MessageHeader';
import { formatCurrency } from '../../utils/validateNumber';
import { fetchPagamento } from '../../store/states/pagamento/actions';
import LegendChartPagamento from '../../components/Charts/LegendChartPagamento';
import { columnsPagamento } from '../PagamentoScreen/interfaces/columnsPagamento';
import { renderPagamento } from '../PagamentoScreen/interfaces/pagamentoDetails';

interface Filters {
  TAGTYPE?: string[];
}

function AnalisePagamentoScreen() {
  const dispatch = useDispatch();
  const loadingPagamentoAnalytics = useSelector((state: RootState) => state.pagamento.loadingPagamentoAnalytics);
  const pagamentoAnalytics = useSelector((state: RootState) => state.pagamento.pagamentoAnalytics);
  const pagamentoAuditAnalytics = useSelector((state: RootState) => state.pagamento.pagamentoAuditAnalytics);
  const pagamentoLoading = useSelector((state: RootState) => state.pagamento.loading);
  const loadingExportAudit = useSelector((state: RootState) => state.pagamento.loadingPagamentoExportAudit);
  const pagamentos = useSelector((state: RootState) => state.pagamento.data);
  const companyIDs = useSelector((state: RootState) => state.empresa.companyID);
  const startDate = useSelector((state: RootState) => state.datas.startDate);
  const endDate = useSelector((state: RootState) => state.datas.endDate);
  const adquirentes = useSelector((state: RootState) => state.adquirente.data);
  const bandeiras = useSelector((state: RootState) => state.bandeira.data);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const [pagamentosTotal, setPagamentosTotal] = useState(0);
  const [totalLiquidoSubFiltro, setTotalLiquidoSubFiltro] = useState(0);
  const [totalBrutoSubFiltro, setTotalBrutoSubFiltro] = useState(0);
  const [currentFilters, setCurrentFilters] = useState<Filters>({});
  const pagamentoChartData = useSelector((state: RootState) => state.pagamento.pagamentoChartData);
  const adquirentesAtivos = new Set(pagamentoChartData.map((data: any) => data.ADQUIRENTENOME));
  const adquirentesFiltradas = adquirentes.filter((adquirente: any) => adquirentesAtivos.has(adquirente.ADQUIRENTENOME));
  const bandeirasAtivas = new Set(pagamentoChartData.map((data: any) => data.BANDEIRANOME));
  const bandeirasFiltradas = bandeiras.filter((bandeira: any) => bandeirasAtivas.has(bandeira.BANDEIRANOME));
  const estabelecimentos = new Set(pagamentoChartData.map((data: any) => data.ESTABELECIMENTONUMERO));

  useEffect(() => {
    setPagamentosTotal(pagamentos.totalList);
    if (currentFilters && currentFilters.TAGTYPE) {
      switch (currentFilters.TAGTYPE[0]) {
        case 'orange':
          setPagamentosTotal(pagamentoAuditAnalytics.CADASTRAR);
          break;
        case 'red':
          setPagamentosTotal(pagamentoAuditAnalytics.DIVERGENCIA);
          break;
        case 'green':
          setPagamentosTotal(pagamentoAuditAnalytics.VALIDA);
          break;
        default:
          setPagamentosTotal(pagamentos.totalList);
          break;
      }
    }
    setTotalLiquidoSubFiltro(pagamentos.totalValorLiquido);
    setTotalBrutoSubFiltro(pagamentos.totalValorBruto);
  }, [pagamentos, currentFilters]);

  const handleExportPdf = () => {
    message.success('Gerando o relatório de pagamentos auditados no formato .pdf, aguarde...');
    dispatch(
      fetchPagamentoAuditadoPdf({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
      }),
    );
  };

  const handleExportXls = () => {
    message.success('Gerando o relatório de pagamentos auditados no formato .xlsx, aguarde...');
    dispatch(
      fetchPagamentoAuditadoXls({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
      }),
    );
  };

  const fetchPagamentos = (page: number, pageSize: number, sortField: any, sortOrder: any, filters: any) => {
    const sort = sortField !== undefined ? sortField : 'PAGAMENTODATAPAGAMENTO';
    const order = sortOrder !== undefined && sortOrder === 'ascend' ? 'ASC' : 'DESC';
    const apiFilters = mapFiltersPagamentoForApi(filters);
    if (filters && filters.TAGTYPE) {
      setCurrentFilters({ TAGTYPE: filters.TAGTYPE });
    }
    dispatch(
      fetchPagamento({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        page,
        pageSize,
        filters: apiFilters,
        sortField: sort,
        sortOrder: order,
      }),
    );
  };

  const resetFilters = () => {
    setCurrentFilters({});
  };

  const handlePageChange = (page: number = 1, pageSize: number = 10, sortField: string = 'PAGAMENTODATAPAGAMENTO', sortOrder: string = 'DESC', filters: any) => {
    setCurrentPage(page);
    fetchPagamentos(page, pageSize, sortField, sortOrder, filters);
  };

  const signalPotencial = () => {
    const ganho = Number(Math.abs(pagamentoAuditAnalytics.TOTALGANHO));
    const perda = Number(Math.abs(pagamentoAuditAnalytics.TOTALPERDA));
    if (ganho > perda) {
      return '+';
    } else if (ganho < perda) {
      return '-';
    } else {
      return '';
    }
  };

  return (
    <AnimatedBanner>
      <Container>
        <>
          <MessageHeader textMessage={textHeaderAnalytics} />
          <Row gutter={16}>
            <Col span={24}>
              <Row gutter={16}>
                <Col flex="5">
                  <StatCard loadingData={loadingPagamentoAnalytics} title="Registros" value={pagamentoAuditAnalytics.TOTAL || 0} icon={<FieldNumberOutlined />} />
                </Col>
                <Col flex="5">
                  <StatCard loadingData={loadingPagamentoAnalytics} title="Válidas" value={pagamentoAuditAnalytics.VALIDA || 0} icon={<SafetyCertificateOutlined />} />
                </Col>
                <Col flex="5">
                  <StatCard loadingData={loadingPagamentoAnalytics} title="Divergências" value={pagamentoAuditAnalytics.DIVERGENCIA || 0} icon={<BugOutlined />} />
                </Col>
                <Col flex="5">
                  <StatCard loadingData={loadingPagamentoAnalytics} title="Sem Cadastro" value={pagamentoAuditAnalytics.CADASTRAR || 0} icon={<QuestionCircleOutlined />} />
                </Col>
                <Col flex="5">
                  <StatCard loadingData={loadingPagamentoAnalytics} title="Ganho" value={formatCurrency(pagamentoAuditAnalytics.TOTALGANHO || 0)} icon={<CaretUpOutlined style={{ color: 'green' }} />} />
                </Col>
                <Col flex="5">
                  <StatCard loadingData={loadingPagamentoAnalytics} title="Perda" value={formatCurrency(pagamentoAuditAnalytics.TOTALPERDA || 0)} icon={<CaretDownOutlined style={{ color: 'red' }} />} />
                </Col>
                <Col flex="5">
                  <StatCard
                    loadingData={loadingPagamentoAnalytics}
                    title="Potencial"
                    value={signalPotencial() + formatCurrency(Math.abs(pagamentoAuditAnalytics.TOTALGANHO) - pagamentoAuditAnalytics.TOTALPERDA || 0)}
                    icon={<FireOutlined style={{ color: 'orange' }} />}
                  />
                </Col>
              </Row>
              {pagamentoLoading ? (
                <Skeleton active />
              ) : (
                <>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <LegendChartPagamento pagamentoAnalytics={pagamentoAnalytics && pagamentoAnalytics.length > 0 ? pagamentoAnalytics : []} />
                  </div>
                  {pagamentos && <DropDownContent loadingExport={loadingExportAudit} transacoes={pagamentos} handleExportXls={handleExportXls} handleExportPdf={handleExportPdf} />}
                </>
              )}
              <TableComponent
                loadingData={pagamentoLoading}
                data={pagamentos.list && pagamentos.list.length > 0 ? pagamentos.list : []}
                columns={columnsPagamento(true, Array.from(adquirentesFiltradas), Array.from(bandeirasFiltradas), Array.from(estabelecimentos), resetFilters)}
                rowKeyId="PAGAMENTOID"
                rowRender={renderPagamento}
                perPage={perPage}
                currentPage={currentPage}
                totalPages={pagamentosTotal}
                onChangePage={handlePageChange}
                expand={true}
                externalFilters={[]}
                totalBrutoSubFiltro={totalBrutoSubFiltro}
                totalLiquidoSubFiltro={totalLiquidoSubFiltro}
              />
            </Col>
          </Row>
        </>
      </Container>
    </AnimatedBanner>
  );
}

export default AnalisePagamentoScreen;
