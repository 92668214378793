import { FETCH_ADQUIRENTE_DATA, FETCH_ADQUIRENTE_SUCCESS, FETCH_ADQUIRENTE_FAILURE } from './types';

export const fetchAdquirente = () => ({
  type: FETCH_ADQUIRENTE_DATA,
});

export const fetchAdquirenteSuccess = (data: any[]) => ({
  type: FETCH_ADQUIRENTE_SUCCESS,
  data,
});

export const fetchAdquirenteFailure = (error: any) => ({
  type: FETCH_ADQUIRENTE_FAILURE,
  payload: error,
});
