import { List, Typography } from 'antd';
import styled from 'styled-components';

export const NotificationItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  cursor: pointer;
  padding: 10px;
  max-width: 600px;
`;

export const StyledList = styled(List)`
  max-height: 350px;
  overflow-y: auto;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

export const StyledListItem = styled(List.Item)`
  transition: background-color 0.3s;
  border-radius: 5px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const StyledTitle = styled(Typography.Text)`
  color: #1890ff;
  font-weight: bold;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-bottom: 5px;
`;

export const StyledDescription = styled.p`
  color: #666;
  margin-bottom: 5px;
  font-size: 14px;
`;

export const StyledDate = styled.p`
  color: #999;
  font-size: 12px;
`;

export const StyledText = styled(Typography.Text)`
  color: #1890ff;
  margin-right: 15px;
`;
