import styled from 'styled-components';

export const TooltipStyle = styled.div`
  background-color: rgba(37, 47, 100, 0.9);
  padding: 10px;
  border: 1px solid #ffffff;
  border-radius: 12px;
  color: white;
  font-size: 12px;
  @media (max-width: 768px) {
    font-size: 10px;
  }
  @media (max-width: 390px) {
    font-size: 8px;
  }
`;

export const TooltipItemContainerStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 0fr);
  gap: 8px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 0fr);
  }
`;
