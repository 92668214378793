import {
  CLEAR_GESTAO_TAXA,
  CREATE_GESTAO_TAXA,
  CREATE_GESTAO_TAXA_FAILURE,
  CREATE_GESTAO_TAXA_SUCCESS,
  DELETE_GESTAO_TAXA,
  DELETE_GESTAO_TAXA_FAILURE,
  DELETE_GESTAO_TAXA_SUCCESS,
  FETCH_GESTAO_TAXA_DATA,
  FETCH_GESTAO_TAXA_FAILURE,
  FETCH_GESTAO_TAXA_SUCCESS,
  UPDATE_GESTAO_TAXA,
  UPDATE_GESTAO_TAXA_FAILURE,
  UPDATE_GESTAO_TAXA_SUCCESS,
} from './types';

export const clearGestaoTaxa = () => ({
  type: CLEAR_GESTAO_TAXA,
});

export const fetchGestaoTaxa = () => ({
  type: FETCH_GESTAO_TAXA_DATA,
});

export const fetchGestaoTaxaSuccess = (data: any[]) => ({
  type: FETCH_GESTAO_TAXA_SUCCESS,
  data,
});

export const fetchGestaoTaxaFailure = (error: any) => ({
  type: FETCH_GESTAO_TAXA_FAILURE,
  payload: error,
});

export const updateGestaoTaxa = (rateId: number, updatedData: any) => ({
  type: UPDATE_GESTAO_TAXA,
  payload: { rateId, updatedData },
});

export const updateGestaoTaxaSuccess = () => ({
  type: UPDATE_GESTAO_TAXA_SUCCESS,
});

export const updateGestaoTaxaFailure = (error: any) => ({
  type: UPDATE_GESTAO_TAXA_FAILURE,
  payload: error,
});

export const createGestaoTaxa = (newRate: any) => ({
  type: CREATE_GESTAO_TAXA,
  payload: {
    newRate,
  },
});

export const createGestaoTaxaSuccess = () => ({
  type: CREATE_GESTAO_TAXA_SUCCESS,
});

export const createGestaoTaxaFailure = (error: any) => ({
  type: CREATE_GESTAO_TAXA_FAILURE,
  payload: error,
});

export const deleteGestaoTaxa = (id: number) => ({
  type: DELETE_GESTAO_TAXA,
  payload: { id },
});

export const deleteGestaoTaxaSuccess = () => ({
  type: DELETE_GESTAO_TAXA_SUCCESS,
});

export const deleteGestaoTaxaFailure = (error: any) => ({
  type: DELETE_GESTAO_TAXA_FAILURE,
  payload: error,
});
