import { useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import backgroundImage from '../../../assets/global/background.svg';
import { Card } from 'antd';
import moment from 'moment';

interface Data {
  ADQUIRENTEID: number;
  PAGAMENTODATAPAGAMENTO: string;
  EMPRESAID: number;
  MAIORTAXADIA: number;
  MEDIATAXADIA: number;
  MENORTAXADIA: number;
  PAGAMENTOTOTALBRUTODIA: string;
}

const groupDataByDate = (data: any, dateFormat: any) => {
  const groupedData = data.reduce((acc: any, curr: any) => {
    const date = moment(curr.PAGAMENTODATAPAGAMENTO).format(dateFormat);
    if (!acc[date]) {
      acc[date] = { ...curr, PAGAMENTODATAPAGAMENTO: date, count: 1 };
    } else {
      acc[date].MAIORTAXADIA = Math.max(acc[date].MAIORTAXADIA, curr.MAIORTAXADIA);
      acc[date].MEDIATAXADIA = (acc[date].MEDIATAXADIA * acc[date].count + curr.MEDIATAXADIA) / (acc[date].count + 1);
      acc[date].MENORTAXADIA = Math.min(acc[date].MENORTAXADIA, curr.MENORTAXADIA);
      acc[date].count += 1;
    }
    return acc;
  }, {});
  return Object.values(groupedData).map((item: any & { count: number }) => {
    const { count, ...rest } = item;
    return rest;
  });
};

const determineDateFormat = (data: any) => {
  const dates = data.map((item: any) => moment(item.PAGAMENTODATAPAGAMENTO, 'YYYY-MM-DD'));
  const minDate = moment.min(dates);
  const maxDate = moment.max(dates);
  const diffDays = maxDate.diff(minDate, 'days');
  return diffDays > 31 ? 'MM/YYYY' : 'DD/MM/YYYY';
};

const fadeInAnimation = `
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}
`;

const LegendChartPagamento = ({ pagamentoAnalytics }: { pagamentoAnalytics: Data[] }) => {
  const dateFormat = useMemo(() => determineDateFormat(pagamentoAnalytics), [pagamentoAnalytics]);
  const formattedData = useMemo(() => groupDataByDate(pagamentoAnalytics, dateFormat), [pagamentoAnalytics, dateFormat]);

  const CustomTooltip = ({ active, payload, label }: { active: boolean; payload: any[]; label: string }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: '#ffffff',
            padding: '10px',
            border: '1px solid #dddddd',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            animation: 'fadeIn 0.3s ease-out',
            color: '#333',
            fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
          }}
        >
          <style>{fadeInAnimation}</style>
          <p style={{ margin: 0, fontWeight: 'bold', fontSize: '14px', color: '#252f64' }}>{`Data: ${label}`}</p>
          {payload.map((entry: any, index: any) => (
            <p key={index} style={{ margin: '5px 0', fontSize: '12px' }}>{`${entry.name}: ${entry.value.toFixed(2)}%`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <Card
      title={<div style={{ color: '#fff' }}>Análise de Taxas</div>}
      bordered={false}
      style={{ background: `url(${backgroundImage})`, backgroundColor: '#252f64', backgroundBlendMode: 'multiply', backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={formattedData} margin={{ right: 45, left: 25, bottom: 45 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#334158" />
          <XAxis dataKey="PAGAMENTODATAPAGAMENTO" stroke="white" angle={-45} textAnchor="end" tick={{ fontSize: 12 }} />
          <YAxis stroke="#FFF" tickFormatter={(value) => `${value.toFixed(2)}%`} />
          <Tooltip content={<CustomTooltip active={false} payload={[]} label={''} />} />
          <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
          <Line type="monotone" dataKey="MAIORTAXADIA" name="Maior Taxa" stroke="#ffc658" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="MEDIATAXADIA" name="Taxa Média" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="MENORTAXADIA" name="Menor Taxa" stroke="#82ca9d" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default LegendChartPagamento;
