import {
  AimOutlined,
  ApiOutlined,
  BankOutlined,
  CaretUpOutlined,
  CarryOutOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloudSyncOutlined,
  CreditCardOutlined,
  DeliveredProcedureOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  ExceptionOutlined,
  ExpandOutlined,
  FieldNumberOutlined,
  FileDoneOutlined,
  FilterOutlined,
  FolderViewOutlined,
  KeyOutlined,
  PaperClipOutlined,
  PartitionOutlined,
  PercentageOutlined,
  SafetyCertificateOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Card, Col, Row } from 'antd';
import { round } from 'lodash';
import moment from 'moment';

export const renderPagamento = (record: any) => {
  return (
    <Card bordered={false} style={{ marginTop: 16 }}>
      <div style={{ marginBottom: 15 }}>
        <CaretUpOutlined style={{ marginRight: 10 }} />
        <strong>Transação de Pagamento</strong>
      </div>
      <Row gutter={16}>
        <Col span={6}>
          <div>
            <CarryOutOutlined style={{ marginRight: 10 }} />
            <span>Data Pagamento: </span>
            <strong>{moment(record.PAGAMENTODATAPAGAMENTO).format('DD/MM/YYYY')}</strong>
          </div>
          <div>
            <ClockCircleOutlined style={{ marginRight: 10 }} />
            <span>Data Venda: </span>
            <strong>{moment(record.PAGAMENTODATAVENDA).format('DD/MM/YYYY')}</strong>
          </div>
          <div>
            <EnvironmentOutlined style={{ marginRight: 10 }} />
            <span>Empresa: </span>
            <strong>{record.EMPRESANOME}</strong>
          </div>
          <div>
            <FieldNumberOutlined style={{ marginRight: 10 }} />
            <span>Estabelecimento: </span>
            <strong>{record.ESTABELECIMENTONUMERO}</strong>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <FileDoneOutlined style={{ marginRight: 10 }} />
            <span>Banco: </span>
            <strong>{record.BANCONOME}</strong>
          </div>
          <div>
            <FileDoneOutlined style={{ marginRight: 10 }} />
            <span>Agência: </span>
            <strong>{record.PAGAMENTOAGENCIA}</strong>
          </div>
          <div>
            <FolderViewOutlined style={{ marginRight: 10 }} />
            <span>Conta: </span>
            <strong>{record.PAGAMENTOCONTA}</strong>
          </div>
          <div>
            <WalletOutlined style={{ marginRight: 10 }} />
            <span>Adquirente: </span>
            <strong>{record.ADQUIRENTENOME}</strong>
          </div>
          <div>
            <BankOutlined style={{ marginRight: 10 }} />
            <span>Bandeira: </span>
            <strong>{record.BANDEIRANOME}</strong>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <CreditCardOutlined style={{ marginRight: 10 }} />
            <span>Cartão: </span>
            <strong>{record.PAGAMENTOCARTAONUMERO}</strong>
          </div>
          <div>
            <SafetyCertificateOutlined style={{ marginRight: 10 }} />
            <span>Autorização: </span>
            <strong>{record.PAGAMENTOAUTORIZACAO}</strong>
          </div>
          <div>
            <KeyOutlined style={{ marginRight: 10 }} />
            <span>NSU: </span>
            <strong>{record.PAGAMENTONSU}</strong>
          </div>
          <div>
            <ApiOutlined style={{ marginRight: 10 }} />
            <span>TID: </span>
            <strong>{record.PAGAMENTOTID}</strong>
          </div>
          <div>
            <PaperClipOutlined style={{ marginRight: 10 }} />
            <span>RO: </span>
            <strong>{record.PAGAMENTORO}</strong>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <DeliveredProcedureOutlined style={{ marginRight: 10 }} />
            <span>Modalidade: </span>
            <strong>{record.MODALIDADENOME}</strong>
          </div>
          <div>
            <FieldNumberOutlined style={{ marginRight: 10 }} />
            <span>Valor Bruto: </span>
            <strong>
              {parseFloat(record.PAGAMENTOVALORBRUTO).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </strong>
          </div>
          <div>
            <PercentageOutlined style={{ marginRight: 10 }} />
            <span>Taxa: </span>
            <strong>{round(record.PAGAMENTOTAXA, 2).toFixed(2)}%</strong>
          </div>
          <div>
            <DollarOutlined style={{ marginRight: 10 }} />
            <span>Valor Desconto: </span>
            <strong>
              {parseFloat(record.PAGAMENTOVALORDESCONTO).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </strong>
          </div>
          {record.PAGAMENTOTAXAANTECIPADO === '0.00' && (
            <div>
              <DollarOutlined style={{ marginRight: 10 }} />
              <span>Valor Líquido: </span>
              <strong>
                {parseFloat(record.PAGAMENTOVALORLIQUIDO).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </strong>
            </div>
          )}
          {record.PAGAMENTOTAXAANTECIPADO > 0 && (
            <>
              <div>
                <p style={{ marginTop: 25 }}>
                  <CheckCircleOutlined /> <strong>PAGAMENTO ANTECIPADO</strong>
                </p>
                <PercentageOutlined style={{ marginRight: 10 }} />
                <span>Taxa Antecipação: </span>
                <strong>{record.PAGAMENTOTAXAANTECIPADO} %</strong>
              </div>
              <div>
                <DollarOutlined style={{ marginRight: 10 }} />
                <span>Valor Desconto Antecipado: </span>
                <strong>
                  {parseFloat(record.PAGAMENTOVALORDESCONTOANTECIPADO).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </strong>
              </div>
              <div>
                <DollarOutlined style={{ marginRight: 10 }} />
                <span>Valor Desconto Total: </span>
                <strong>
                  {(
                    parseFloat(record.PAGAMENTOVALORDESCONTO) + parseFloat(record.PAGAMENTOVALORDESCONTOANTECIPADO)
                  ).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </strong>
              </div>
              <div>
                <DollarOutlined style={{ marginRight: 10 }} />
                <span>Valor Líquido: </span>
                <strong>
                  {parseFloat(record.PAGAMENTOVALORLIQUIDOANTECIPADO).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </strong>
              </div>
            </>
          )}
          {record.PAGAMENTOMOTIVOAJUSTE !== null && (
            <>
              <div>
                <p style={{ marginTop: 25 }}>
                  <CheckCircleOutlined /> <strong>TRANSAÇÃO DE AJUSTE</strong>
                </p>
                <ExpandOutlined style={{ marginRight: 10 }} />
                <span>Motivo: </span>
                <strong>{record.PAGAMENTOMOTIVOAJUSTE}</strong>
              </div>
              <div>
                <FilterOutlined style={{ marginRight: 10 }} />
                <span>Tipo: </span>
                <strong>
                  {record.PAGAMENTOTIPOTRANSACAOID === 7 || record.PAGAMENTOTIPOTRANSACAOID === 9
                    ? 'CRÉDITO'
                    : 'DÉBITO'}
                </strong>
              </div>
            </>
          )}
        </Col>
        <Col span={6}>
          <div style={{ marginTop: 10 }}>
            <PartitionOutlined style={{ marginRight: 10 }} />
            <span>Total Parcela(s): </span>
            <strong>{record.PAGAMENTOTOTALPARCELA}</strong>
          </div>
          <div>
            <AimOutlined style={{ marginRight: 10 }} />
            <span>Parcela Atual: </span>
            <strong>{record.PAGAMENTOPARCELA}</strong>
          </div>
        </Col>
        <Col span={6}>
          <div style={{ marginTop: 10 }}>
            <CloudSyncOutlined style={{ marginRight: 10 }} />
            <span>Status: </span>
            <strong>{record.PAGAMENTOSTATUSCONCILIACAO ? 'CONCILIADO' : 'NÃO CONCILIADO'}</strong>
          </div>
          <div>
            <ExceptionOutlined style={{ marginRight: 10 }} />
            <span>Observação: </span>
            <strong>{record.PAGAMENTOOBSERVACAO}</strong>
          </div>
        </Col>
      </Row>
    </Card>
  );
};
