import { FETCH_MODALIDADE_DATA, FETCH_MODALIDADE_SUCCESS, FETCH_MODALIDADE_FAILURE } from './types';

export const fetchModalidade = () => ({
  type: FETCH_MODALIDADE_DATA,
});

export const fetchModalidadeSuccess = (data: any[]) => ({
  type: FETCH_MODALIDADE_SUCCESS,
  data,
});

export const fetchModalidadeFailure = (error: any) => ({
  type: FETCH_MODALIDADE_FAILURE,
  payload: error,
});
