import { Reducer } from 'redux';
import { FETCH_USUARIO_REGRA, FETCH_USUARIO_REGRA_SUCCESS, FETCH_USUARIO_REGRA_FAILURE } from './types';

const initialState = {
  loading: false,
};

export const reducer: Reducer<any> = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_USUARIO_REGRA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USUARIO_REGRA_SUCCESS:
      return {
        ...state,
        data: action.data.slice(),
        loading: false,
      };
    case FETCH_USUARIO_REGRA_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
