import {
  CLEAR_VENDA_DATA,
  FETCH_VENDA_DATA,
  FETCH_VENDA_FAILURE,
  FETCH_VENDA_SUCCESS,
  FETCH_VENDA_PDF,
  FETCH_VENDA_PDF_FAILURE,
  FETCH_VENDA_PDF_SUCCESS,
  FETCH_VENDA_AUDITADA_PDF,
  FETCH_VENDA_AUDITADA_PDF_FAILURE,
  FETCH_VENDA_AUDITADA_PDF_SUCCESS,
  FETCH_VENDA_TOTALS_FAILURE,
  FETCH_VENDA_TOTALS_SUCCESS,
  FETCH_VENDA_CHART_DATA,
  FETCH_VENDA_CHART_DATA_SUCCESS,
  FETCH_VENDA_CHART_DATA_FAILURE,
  FETCH_VENDA_ANALYTICS,
  FETCH_VENDA_ANALYTICS_SUCCESS,
  FETCH_VENDA_ANALYTICS_FAILURE,
  SET_VENDA_SORT_ORDER,
  CLEAR_VENDA_SORT_ORDER,
  FETCH_VENDA_TOTALS,
  FETCH_VENDA_AUDIT_ANALYTICS,
  FETCH_VENDA_AUDIT_ANALYTICS_FAILURE,
  FETCH_VENDA_AUDIT_ANALYTICS_SUCCESS,
  FETCH_VENDA_XLS,
  FETCH_VENDA_XLS_SUCCESS,
  FETCH_VENDA_XLS_FAILURE,
  FETCH_VENDA_AUDITADA_XLS,
  FETCH_VENDA_AUDITADA_XLS_SUCCESS,
  FETCH_VENDA_AUDITADA_XLS_FAILURE,
} from './types';

export const setVendaSortOrder = (field: any, order: any) => ({
  type: SET_VENDA_SORT_ORDER,
  payload: { field, order },
});

export const clearVendaSortOrder = () => ({
  type: CLEAR_VENDA_SORT_ORDER,
});

export const clearVendaData = () => ({
  type: CLEAR_VENDA_DATA,
});

export const fetchVendaAuditAnalytics = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
  type: FETCH_VENDA_AUDIT_ANALYTICS,
  payload,
});

export const fetchVendaAuditAnalyticsSuccess = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
  type: FETCH_VENDA_AUDIT_ANALYTICS_SUCCESS,
  payload,
});

export const fetchVendaAuditAnalyticsFailure = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
  type: FETCH_VENDA_AUDIT_ANALYTICS_FAILURE,
  payload,
});

export const fetchVendaAnalytics = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
  type: FETCH_VENDA_ANALYTICS,
  payload,
});

export const fetchVendaAnalyticsSuccess = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
  type: FETCH_VENDA_ANALYTICS_SUCCESS,
  payload,
});

export const fetchVendaAnalyticsFailture = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
  type: FETCH_VENDA_ANALYTICS_FAILURE,
  payload,
});

export const fetchVendaChartData = (payload: { company_id: number | number[]; start_date: any; end_date: any }) => ({
  type: FETCH_VENDA_CHART_DATA,
  payload,
});

export const fetchVendaChartDataSuccess = (data: any) => ({
  type: FETCH_VENDA_CHART_DATA_SUCCESS,
  payload: data,
});

export const fetchVendaChartDataFailure = (error: any) => ({
  type: FETCH_VENDA_CHART_DATA_FAILURE,
  payload: error,
});

export const fetchVendaTotals = (data: any) => ({
  type: FETCH_VENDA_TOTALS,
  payload: data,
});

export const fetchVendaTotalsSuccess = (data: any) => ({
  type: FETCH_VENDA_TOTALS_SUCCESS,
  payload: data,
});

export const fetchVendaTotalsFailure = (error: any) => ({
  type: FETCH_VENDA_TOTALS_FAILURE,
  payload: error,
});

export const fetchVenda = (payload: { company_id: number | number[]; start_date: any; end_date: any; page: number; pageSize: number; filters?: Record<string, any>; sortField: any; sortOrder: any }) => ({
  type: FETCH_VENDA_DATA,
  payload,
});

export const fetchVendaSuccess = (data: any) => ({
  type: FETCH_VENDA_SUCCESS,
  payload: data,
});

export const fetchVendaFailure = (error: any) => ({
  type: FETCH_VENDA_FAILURE,
  payload: error,
});

export const fetchVendaXls = (payload: { company_id: number | number[]; start_date: string; end_date: string }) => ({
  type: FETCH_VENDA_XLS,
  payload,
});

export const fetchVendaXlsSuccess = () => ({
  type: FETCH_VENDA_XLS_SUCCESS,
});

export const fetchVendaXlsFailure = (error: any) => ({
  type: FETCH_VENDA_XLS_FAILURE,
  payload: error,
});

export const fetchVendaPdf = (payload: { company_id: number | number[]; start_date: string; end_date: string }) => ({
  type: FETCH_VENDA_PDF,
  payload,
});

export const fetchVendaPdfSuccess = () => ({
  type: FETCH_VENDA_PDF_SUCCESS,
});

export const fetchVendaPdfFailure = (error: any) => ({
  type: FETCH_VENDA_PDF_FAILURE,
  payload: error,
});

export const fetchVendaAuditadaPdf = (payload: {}) => ({
  type: FETCH_VENDA_AUDITADA_PDF,
  payload,
});

export const fetchVendaAuditadaPdfSuccess = () => ({
  type: FETCH_VENDA_AUDITADA_PDF_SUCCESS,
});

export const fetchVendaAuditadaPdfFailure = (error: any) => ({
  type: FETCH_VENDA_AUDITADA_PDF_FAILURE,
  payload: error,
});

export const fetchVendaAuditadaXls = (payload: {}) => ({
  type: FETCH_VENDA_AUDITADA_XLS,
  payload,
});

export const fetchVendaAuditadaXlsSuccess = () => ({
  type: FETCH_VENDA_AUDITADA_XLS_SUCCESS,
});

export const fetchVendaAuditadaXlsFailure = (error: any) => ({
  type: FETCH_VENDA_AUDITADA_XLS_FAILURE,
  payload: error,
});
