import styled from 'styled-components';

export const ModalDisplay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(37, 47, 100, 0.7), rgba(37, 47, 100, 0.5));
  backdrop-filter: blur(10px);
  z-index: 1000;
`;
