import { takeLatest, call, put } from 'redux-saga/effects';
import { proposalApi, recoverPasswordApi, resetPasswordApi } from '../../target-api/api';
import { message } from 'antd';
import { proposalSuccess, recoverPasswordSuccess, resetPasswordSuccess } from './actions';
import history from '../../../services/history';
import { PROPOSTA, RECOVER_PASSWORD, RESET_PASSWORD } from './types';

export function* recoverPasswordSaga(action: any) {
  try {
    const { subdomain, email } = action.payload;
    yield call(recoverPasswordApi, subdomain, email);
    yield put(recoverPasswordSuccess());
    history.push('/');
  } catch (error: any) {
    message.warning('Não foi possível enviar a recuperação de e-mail, revise os dados e tente novamente!');
    yield put(recoverPasswordSuccess());
  }
}

export function* resetPasswordSaga(action: any) {
  try {
    const { subdomain, token, password } = action.payload;
    yield call(resetPasswordApi, subdomain, token, password);
    yield put(resetPasswordSuccess());
    history.push('/');
  } catch (error: any) {
    message.warning('Não foi possível resetar a recuperação de e-mail, revise os dados e tente novamente!');
    yield put(recoverPasswordSuccess());
  }
}

export function* proposalSaga(action: any) {
  try {
    const { proposal } = action.payload;
    yield call(proposalApi, proposal);
    yield put(proposalSuccess());
  } catch (error: any) {
    message.warning('Não foi possível enviar a proposta, revise os dados e tente novamente!');
    yield put(proposalSuccess());
  }
}

export function* watchUsuarioSaga() {
  yield takeLatest(RECOVER_PASSWORD, recoverPasswordSaga);
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
  yield takeLatest(PROPOSTA, proposalSaga);
}
