import styled, { keyframes } from 'styled-components';
import { Tag } from 'antd';

export const fadeInOut = keyframes`
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
`;

export const StyledTag = styled(Tag)`
  display: inline-flex;
  align-items: center;
  .alert-icon {
    margin-right: 4px;
    animation: ${fadeInOut} 2s infinite;
  }
`;

export const StyledSpan = styled.span`
  font-weight: bold;
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
`;
