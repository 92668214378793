import React, { useState } from 'react';
import { Modal, Button, Input } from 'antd';
import { ModalDisplay } from './styled';
import { WhatsAppOutlined } from '@ant-design/icons';
import { getSubdomain } from '../../../utils/validateCustomer';
const { TextArea } = Input;

export interface UserType {
  USUARIONAME: string;
  user_group_id: number;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: UserType;
}

export const ModalHelp: React.FC<ModalProps> = ({ isOpen, onClose, user }) => {
  const [userQuestion, setUserQuestion] = useState<string>('');
  const subdomain = getSubdomain();

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserQuestion(e.target.value);
  };

  const handleSubmit = () => {
    const encodedQuestion = encodeURIComponent(userQuestion);
    const phoneNumber = process.env.REACT_APP_PHONE_NUMBER;
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=Domínio Card: ${subdomain} | Usuário Card: ${user.USUARIONAME} | Mensagem: ${encodedQuestion}`;
    window.open(whatsappLink, '_blank');
    onClose();
    setUserQuestion('');
  };

  return (
    <ModalDisplay isOpen={isOpen}>
      <Modal
        title="Fale conosco agora mesmo pelo WhatsApp"
        visible={isOpen}
        onCancel={onClose}
        footer={[
          <Button key="back" size="large" onClick={onClose} style={{ fontSize: '14px' }}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" size="large" onClick={handleSubmit} style={{ fontSize: '14px' }}>
            <WhatsAppOutlined style={{ fontSize: '18px', marginRight: '10px' }}/>
            <strong> Iniciar Conversa</strong>
          </Button>,
        ]}
      >
        <TextArea rows={4} value={userQuestion} onChange={handleInputChange} />
      </Modal>
    </ModalDisplay>
  );
};
