import { Button, Card, Col, Row, Skeleton } from 'antd';
import { MonitorOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Container } from '../../styles/GlobalStyles';
import TableComponent from '../../components/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { useEffect, useState } from 'react';
import ModalRateRegister from '../../components/Modals/ModalRateRegister';
import { deleteGestaoTaxa, fetchGestaoTaxa, updateGestaoTaxa } from '../../store/states/gestao-taxa/actions';
import { textHeaderTaxas } from './interfaces/all';
import { columnsGestaoTaxa } from './interfaces/columnsGestaoTaxa';
import { ModalGestaoTaxasActions } from '../../components/Modals/ModalGestaoTaxasActions';
import Lottie from 'react-lottie';
import animationData from '../../assets/rate-register/health_rate.json';
import { StatCard } from '../../components/Charts/StatCard';
import AnimatedBanner from '../../components/AnimatedBanner';
import MessageHeader from '../../components/MessageHeader';
import { StyledCard } from './styled';

function GestaoTaxaScreen() {
  const dispatch = useDispatch();
  const gestaoTaxas = useSelector((state: RootState) => state.gestaoTaxa.data);
  const loadingGestaoTaxas = useSelector((state: RootState) => state.gestaoTaxa.loading);
  const [taxas, setTaxas] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    setTaxas(gestaoTaxas);
  }, [gestaoTaxas]);

  const handleCreateClick = () => {
    setIsModalOpen(true);
  };

  const handleUpdateClick = () => {
    dispatch(fetchGestaoTaxa());
  };

  const handleDelete = (id: any) => {
    dispatch(deleteGestaoTaxa(id));
  };

  const handleEdit = (rateId: number, updatedData: any) => {
    dispatch(updateGestaoTaxa(rateId, updatedData));
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <AnimatedBanner>
      <Container>
        <MessageHeader textMessage={textHeaderTaxas} />
        {loadingGestaoTaxas ? (
          <>
            <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
              <Col span={24}>
                <Card style={{ marginTop: 10, background: '#252f64' }}>
                  <Skeleton active />
                </Card>
              </Col>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
              <Skeleton active />
            </div>
          </>
        ) : (
          <>
            <Row gutter={16}>
              <Col span={24}>
                <StatCard loadingData={loadingGestaoTaxas} title="Total de Taxas Cadastradas" value={taxas && taxas.length > 0 ? taxas.length : 0} icon={<MonitorOutlined />} />
              </Col>
            </Row>
            <StyledCard
              style={{ marginTop: 10, padding: 0 }}
              title={
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Lottie options={defaultOptions} height={30} width={30} />
                  <span style={{ marginLeft: 10, opacity: 0.7 }}>Gestão de Taxas por Empresa / Estabelecimento</span>
                </div>
              }
            >
              {taxas && taxas.length > 0 && (
                <TableComponent
                  loadingData={loadingGestaoTaxas}
                  data={taxas}
                  columns={columnsGestaoTaxa()}
                  rowKeyId="GESTAOTAXAID"
                  rowRender={(record: any) => <ModalGestaoTaxasActions record={record} handleDelete={handleDelete} handleEdit={handleEdit} />}
                  perPage={5}
                  expand={true}
                />
              )}
              <Button style={{ backgroundColor: '#252f64' }} onClick={handleCreateClick} type="primary" htmlType="submit">
                <PlusCircleOutlined /> Nova Taxa
              </Button>{' '}
              <Button style={{ backgroundColor: '#252f64' }} loading={loadingGestaoTaxas} onClick={handleUpdateClick} type="primary" htmlType="submit">
                Atualizar Lista
              </Button>
            </StyledCard>
          </>
        )}
        <ModalRateRegister title={'Nova Taxa de Contrato'} isOpen={isModalOpen} onClose={closeModal} />
      </Container>
    </AnimatedBanner>
  );
}

export default GestaoTaxaScreen;
