import { Reducer } from 'react';
import {
  CLEAR_PAGAMENTOS_CONSOLIDADOS_DATA,
  FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA_FAILURE,
  FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA_SUCCESS,
  FETCH_PAGAMENTOS_CONSOLIDADOS_DATA,
  FETCH_PAGAMENTOS_CONSOLIDADOS_FAILURE,
  FETCH_PAGAMENTOS_CONSOLIDADOS_PDF,
  FETCH_PAGAMENTOS_CONSOLIDADOS_PDF_FAILURE,
  FETCH_PAGAMENTOS_CONSOLIDADOS_PDF_SUCCESS,
  FETCH_PAGAMENTOS_CONSOLIDADOS_SUCCESS,
  FETCH_PAGAMENTOS_CONSOLIDADOS_XLS,
  FETCH_PAGAMENTOS_CONSOLIDADOS_XLS_FAILURE,
  FETCH_PAGAMENTOS_CONSOLIDADOS_XLS_SUCCESS,
  FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA,
} from './types';

const pagamentoConsolidadoState = {
  data: {},
  consolidadosFiltroData: {},
  loading: false,
  loadingFiltros: false,
  loadingConsolidadoExport: false,
  error: null,
  sortField: null,
  sortOrder: null,
};

export const reducer: Reducer<any, any> = (state = pagamentoConsolidadoState, action: any) => {
  switch (action.type) {
    case CLEAR_PAGAMENTOS_CONSOLIDADOS_DATA:
      return { ...pagamentoConsolidadoState };
    case FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA:
      return { ...state, loadingFiltros: true };
    case FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA_SUCCESS:
      return { ...state, consolidadosFiltroData: action.payload || [] };
    case FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA_FAILURE:
      return { ...state, error: action.payload };
    case FETCH_PAGAMENTOS_CONSOLIDADOS_DATA:
      return { ...state, loading: true };
    case FETCH_PAGAMENTOS_CONSOLIDADOS_SUCCESS:
      return { ...state, loading: false, data: action.payload || [] };
    case FETCH_PAGAMENTOS_CONSOLIDADOS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_PAGAMENTOS_CONSOLIDADOS_PDF:
      return { ...state, loadingConsolidadoExport: true };
    case FETCH_PAGAMENTOS_CONSOLIDADOS_PDF_SUCCESS:
      return { ...state, loadingConsolidadoExport: false };
    case FETCH_PAGAMENTOS_CONSOLIDADOS_PDF_FAILURE:
      return { ...state, loadingConsolidadoExport: false };
    case FETCH_PAGAMENTOS_CONSOLIDADOS_XLS:
      return { ...state, loadingConsolidadoExport: true };
    case FETCH_PAGAMENTOS_CONSOLIDADOS_XLS_SUCCESS:
      return { ...state, loadingConsolidadoExport: false };
    case FETCH_PAGAMENTOS_CONSOLIDADOS_XLS_FAILURE:
      return { ...state, loadingConsolidadoExport: false };

    default:
      return state;
  }
};
