import { useSpring, animated } from 'react-spring';
import React, { ReactNode } from 'react';

interface AnimatedBannerProps {
  children: ReactNode;
}

const AnimatedBanner: React.FC<AnimatedBannerProps> = ({ children }) => {
  const bannerSpring = useSpring({
    opacity: 1,
    transform: 'translateX(0)',
    from: { opacity: 0, transform: 'translateX(-1%)' },
    delay: 100,
  });

  return <animated.div style={bannerSpring}>{children}</animated.div>;
};

export default AnimatedBanner;
