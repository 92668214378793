import { FETCH_BANDEIRA_DATA, FETCH_BANDEIRA_SUCCESS, FETCH_BANDEIRA_FAILURE } from './types';

export const fetchBandeira = () => ({
  type: FETCH_BANDEIRA_DATA,
});

export const fetchBandeiraSuccess = (data: any[]) => ({
  type: FETCH_BANDEIRA_SUCCESS,
  data,
});

export const fetchBandeiraFailure = (error: any) => ({
  type: FETCH_BANDEIRA_FAILURE,
  payload: error,
});
