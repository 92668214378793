export const CLEAR_PAGAMENTOS_CONSOLIDADOS_DATA = 'CLEAR_PAGAMENTOS_CONSOLIDADOS_DATA';
export const FETCH_PAGAMENTOS_CONSOLIDADOS_DATA = 'FETCH_PAGAMENTOS_CONSOLIDADOS_DATA'
export const FETCH_PAGAMENTOS_CONSOLIDADOS_SUCCESS = 'FETCH_PAGAMENTOS_CONSOLIDADOS_SUCCESS'
export const FETCH_PAGAMENTOS_CONSOLIDADOS_FAILURE = 'FETCH_PAGAMENTOS_CONSOLIDADOS_FAILURE'
export const FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA = "FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA"
export const FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA_SUCCESS = 'FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA_SUCCESS';
export const FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA_FAILURE = 'FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA_FAILURE';
export const FETCH_PAGAMENTOS_CONSOLIDADOS_PDF = 'FETCH_PAGAMENTOS_CONSOLIDADOS_PDF'
export const FETCH_PAGAMENTOS_CONSOLIDADOS_PDF_SUCCESS = 'FETCH_PAGAMENTOS_CONSOLIDADOS_PDF_SUCCESS'
export const FETCH_PAGAMENTOS_CONSOLIDADOS_PDF_FAILURE = 'FETCH_PAGAMENTOS_CONSOLIDADOS_PDF_FAILURE'
export const FETCH_PAGAMENTOS_CONSOLIDADOS_XLS = 'FETCH_PAGAMENTOS_CONSOLIDADOS_XLS'
export const FETCH_PAGAMENTOS_CONSOLIDADOS_XLS_SUCCESS = 'FETCH_PAGAMENTOS_CONSOLIDADOS_XLS_SUCCESS'
export const FETCH_PAGAMENTOS_CONSOLIDADOS_XLS_FAILURE = 'FETCH_PAGAMENTOS_CONSOLIDADOS_XLS_FAILURE'

