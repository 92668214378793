import {
  PROPOSTA,
  PROPOSTA_SUCCESS,
  RECOVER_PASSWORD,
  RECOVER_PASSWORD_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
} from './types';

export const recoverPassword = (subdomain: string, email: string) => ({
  type: RECOVER_PASSWORD,
  payload: { subdomain, email },
});

export const recoverPasswordSuccess = () => ({
  type: RECOVER_PASSWORD_SUCCESS,
});

export const resetPassword = (subdomain: string, token: string | null, password: string) => ({
  type: RESET_PASSWORD,
  payload: { subdomain, token, password },
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const proposal = (proposal: any) => ({
  type: PROPOSTA,
  payload: { proposal },
});

export const proposalSuccess = () => ({
  type: PROPOSTA_SUCCESS,
});
