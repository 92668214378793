import { Col, message, Row, Skeleton } from 'antd';
import AnimatedBanner from '../../components/AnimatedBanner';
import { Container } from '../../styles/GlobalStyles';
import { StatCard } from '../../components/Charts/StatCard';
import { DollarCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { DropDownContent } from '../../components/DropDownContent';
import { fetchAgendaRecebimentos, fetchAgendaRecebimentosPdf, fetchAgendaRecebimentosXls } from '../../store/states/agendaRecebimentos/actions';
import CollapseFilter from '../../components/CollapseFilter';
import { useEffect, useState } from 'react';
import { mapFiltersAgendaRecebimentosForApi } from '../../utils/validateFilter';
import { columnsRecebimento } from './interfaces/columnsRecebimento';
import { renderRecebimento } from './interfaces/recebimentoDetails';
import TableComponent from '../../components/TablePagination';

function AgendaRecebimentosScreen() {
  const dispatch = useDispatch();
  const [showAcquirer, setShowAcquirer] = useState(() => {
    const persistedState = localStorage.getItem('showAcquirerAgendaRecebimentos');
    return persistedState ? JSON.parse(persistedState) : true;
  });
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);
  const [selectedAdquirente, setSelectedAdquirente] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedBanco, setSelectedBanco] = useState(null);
  const [perPage] = useState(10);
  const [recebimentosTotal, setRecebimentosTotal] = useState(0);
  const [totalBrutoSubFiltro, setTotalBrutoSubFiltro] = useState(0);
  const [totalLiquidoSubFiltro, setTotalLiquidoSubFiltro] = useState(0);

  const recebimentos = useSelector((state: RootState) => state.agendaRecebimentos.data);
  const agendaRecebimentosTotals = useSelector((state: RootState) => state.agendaRecebimentos.agendaRecebimentosTotals);
  const loadingTotals = useSelector((state: RootState) => state.agendaRecebimentos.loadingTotals);
  const recebimentoLoading = useSelector((state: RootState) => state.agendaRecebimentos.loading);
  const recebimentoLoadingExport = useSelector((state: RootState) => state.agendaRecebimentos.loadingRecebimentoExport);
  const companyIDs = useSelector((state: RootState) => state.empresa.companyID);
  const startDate = useSelector((state: RootState) => state.datas.startDate);
  const endDate = useSelector((state: RootState) => state.datas.endDate);
  const adquirentes = useSelector((state: RootState) => state.adquirente.data);
  const bancos = useSelector((state: RootState) => state.banco.data);
  const bandeiras = useSelector((state: RootState) => state.bandeira.data);
  const recebimentoChartData = useSelector((state: RootState) => state.agendaRecebimentos.recebimentoChartData);
  
  const adquirentesAtivos = new Set(
    Array.isArray(recebimentoChartData) ? recebimentoChartData.map((data: any) => data.ADQUIRENTENOME) : []
  );
  const adquirentesFiltradas = adquirentes.filter((adquirente: any) => adquirentesAtivos.has(adquirente.ADQUIRENTENOME));
  
  const bancosAtivos = new Set(
    Array.isArray(recebimentoChartData) ? recebimentoChartData.map((data: any) => data.BANCONOME) : []
  );
  const bancosFiltrados = bancos.filter((banco: any) => bancosAtivos.has(banco.BANCONOME));
  const bandeirasAtivas = new Set(
    Array.isArray(recebimentoChartData) ? recebimentoChartData.map((data: any) => data.BANDEIRANOME) : []
  );
  const bandeirasFiltradas = bandeiras.filter((bandeira: any) => bandeirasAtivas.has(bandeira.BANDEIRANOME));
  const estabelecimentos = new Set(
    Array.isArray(recebimentoChartData) ? recebimentoChartData.map((data: any) => data.ESTABELECIMENTONUMERO) : []
  );


  
  useEffect(() => {
    setRecebimentosTotal(recebimentos.totalList);
    setTotalLiquidoSubFiltro(recebimentos.totalValorLiquido);
    setTotalBrutoSubFiltro(recebimentos.totalValorBruto);
  }, [recebimentos]);

  useEffect(() => {
    localStorage.setItem('showAcquirerAgendaRecebimentos', JSON.stringify(showAcquirer));
  }, [showAcquirer]);

  const handleExportXls = () => {
    message.success('Gerando o relatório de recebíveis no formato .xlsx, aguarde...');
    dispatch(
      fetchAgendaRecebimentosXls({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
      }),
    );
  };

  const handleExportPdf = () => {
    message.success('Gerando o relatório de recebíveis no formato .pdf, aguarde...');
    dispatch(
      fetchAgendaRecebimentosPdf({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
      }),
    );
  };

  const handleAdquirenteSelect = (adquirenteName: any) => {
    if (selectedAdquirente === adquirenteName) {
      setSelectedAdquirente(null);
      setActiveFilter(null);
      fetchRecebimentos(1, pageSize, 'VENDADATAPREVISAO', 'DESC', mapFiltersAgendaRecebimentosForApi({ ADQUIRENTENOME: null }));
    } else {
      const newFilters = selectedAdquirente === adquirenteName ? {} : mapFiltersAgendaRecebimentosForApi({ ADQUIRENTENOME: adquirenteName });
      setFilters(newFilters);
      fetchRecebimentos(currentPage, pageSize, 'VENDADATAPREVISAO', 'DESC', newFilters);
      setSelectedAdquirente(adquirenteName);
      setActiveFilter(adquirenteName);
      setSelectedBanco(null);
    }
  };

  const handleBancoSelect = (bancoName: any) => {
    if (selectedBanco === bancoName) {
      setSelectedBanco(null);
      setActiveFilter(null);
      fetchRecebimentos(1, pageSize, 'VENDADATAPREVISAO', 'DESC', mapFiltersAgendaRecebimentosForApi({ BANCONOME: null }));
    } else {
      const newFilters = selectedBanco === bancoName ? {} : mapFiltersAgendaRecebimentosForApi({ BANCONOME: bancoName });
      setFilters(newFilters);
      fetchRecebimentos(currentPage, pageSize, 'VENDADATAPREVISAO', 'DESC', newFilters);
      setSelectedBanco(bancoName);
      setActiveFilter(bancoName);
      setSelectedAdquirente(null);
    }
  };

  const fetchRecebimentos = (page: number, pageSize: number, sortField: any, sortOrder: any, filters: any) => {
    const order = sortOrder !== undefined && sortOrder === 'ascend' ? 'ASC' : 'DESC';
    const apiFilters = mapFiltersAgendaRecebimentosForApi(filters);
    dispatch(
      fetchAgendaRecebimentos({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        page,
        pageSize,
        filters: apiFilters,
        sortField,
        sortOrder: order,
      }),
    );
  };

  const handlePageChange = (page: number = 1, pageSize: number = 10, sortField: string = 'VENDADATAPREVISAO', sortOrder: string = 'DESC', filters: any) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchRecebimentos(page, pageSize, sortField, sortOrder, filters);
  };

  return (
    <AnimatedBanner>
      <Container>
        <>
          <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
            <Col flex="5">
              <StatCard
                loadingData={loadingTotals}
                title="Bruto"
                value={(agendaRecebimentosTotals.TotalBruto || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
            <Col flex="5">
              <StatCard
                loadingData={loadingTotals}
                title="Desconto"
                value={(agendaRecebimentosTotals.TotalDesconto || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
            <Col flex="5">
              <StatCard
                loadingData={loadingTotals}
                title="Despesa"
                value={(agendaRecebimentosTotals.TotalDespesa || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
            <Col flex="5">
              <StatCard
                loadingData={loadingTotals}
                title="Credito"
                value={(agendaRecebimentosTotals.TotalCredito || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
            <Col flex="5">
              <StatCard
                loadingData={loadingTotals}
                title="Líquido"
                value={(agendaRecebimentosTotals.TotalLiquido || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
            <Col flex="5">
              <StatCard
                loadingData={loadingTotals}
                title="Atrasado"
                value={(agendaRecebimentosTotals.TotalAtrasado || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
            <Col flex="5">
              <StatCard
                loadingData={loadingTotals}
                title="A Receber"
                value={(agendaRecebimentosTotals.TotalAReceber || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
          </Row>

          {recebimentoLoading ? (
            <Skeleton active />
          ) : (
            <>
              {recebimentos.list && 
              <DropDownContent 
                loadingExport={recebimentoLoadingExport} 
                transacoes={recebimentos} 
                handleExportXls={handleExportXls} 
                handleExportPdf={handleExportPdf} />}
              <CollapseFilter
                isCollapseOpen={isCollapseOpen}
                setIsCollapseOpen={setIsCollapseOpen}
                setShowAcquirer={setShowAcquirer}
                showAcquirer={showAcquirer}
                handleAdquirenteSelect={handleAdquirenteSelect}
                handleBancoSelect={handleBancoSelect}
                activeFilter={activeFilter}
                adquirentes={adquirentesFiltradas}
                bancos={bancosFiltrados}
              />
            </>
          )}
          <TableComponent
            loadingData={recebimentoLoading}
            data={recebimentos.list && recebimentos.list.length > 0 ? recebimentos.list : []}
            columns={columnsRecebimento(false, Array.from(adquirentesFiltradas), Array.from(bandeirasFiltradas), Array.from(estabelecimentos))}
            rowKeyId="VENDAID"
            rowRender={renderRecebimento}
            perPage={perPage}
            currentPage={currentPage}
            totalPages={recebimentosTotal}
            onChangePage={handlePageChange}
            expand={true}
            externalFilters={filters}
            totalBrutoSubFiltro={totalBrutoSubFiltro}
            totalLiquidoSubFiltro={totalLiquidoSubFiltro}
          />
        </>
      </Container>
    </AnimatedBanner>
  );
}

export default AgendaRecebimentosScreen;
