import { takeLatest, call, put } from 'redux-saga/effects';
import { estabelecimentoApi } from '../../target-api/api';
import { fetchEstabelecimentoSuccess } from './actions';
import { FETCH_ESTABELECIMENTO_DATA } from './types';
import { message } from 'antd';

export function* fetchEstabelecimentoSaga(action: any) {
  try {
    const { empresa_id } = action.payload;
    const data: any[] = yield call(estabelecimentoApi, empresa_id);
    if (data !== undefined) {
      yield put(fetchEstabelecimentoSuccess(data));
    } else {
      message.warning('Falha ao retornar lista de estabelecimentos!');
    }
  } catch (error: any) {
    message.error(error);
  }
}

export function* watchEstabelecimentoSaga() {
  yield takeLatest(FETCH_ESTABELECIMENTO_DATA, fetchEstabelecimentoSaga);
}
