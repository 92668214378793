import { Reducer } from 'redux';
import { FETCH_ESTABELECIMENTO_SUCCESS, FETCH_ESTABELECIMENTO_FAILURE } from './types';

export const reducer: Reducer<any, any> = (state = {}, action: any) => {
  switch (action.type) {
    case FETCH_ESTABELECIMENTO_SUCCESS:
      return {
        ...state,
        data: action.data.slice(),
      };
    case FETCH_ESTABELECIMENTO_FAILURE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
