import { Menu } from 'antd';
import styled from 'styled-components';

export const CustomSelectDiv = styled.div``;

export const CustomSpan = styled.span`
  @media (max-width: 1300px) {
    display: none;
  }
  @media (max-width: 550px) {
    display: block;
  }
`;

export const StyledSubMenu = styled(Menu.SubMenu)``;

export const StyledMenuItem = styled(Menu.Item)`
  @media (max-width: 576px) {
    font-size: 12px;
  }
`;
