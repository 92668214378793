import { takeLatest, call, put } from 'redux-saga/effects';
import { bancoApi } from '../../target-api/api';
import { toast } from 'react-toastify';
import { fetchBancoSuccess } from './actions';
import { FETCH_BANCO_DATA } from './types';

export function* fetchBancoSaga() {
  try {
    const data: any[] = yield call(bancoApi);
    if (data !== undefined) {
      yield put(fetchBancoSuccess(data));
    } else {
      toast.warn('Falha ao retornar lista de bancos!');
    }
  } catch (error: any) {
    toast.error(error);
  }
}

export function* watchBancoSaga() {
  yield takeLatest(FETCH_BANCO_DATA, fetchBancoSaga);
}
