import styled, { keyframes } from 'styled-components';
import { Sector } from 'recharts';

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const PieChartContainer = styled.div`
  flex: 1;
`;

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  max-width: 250px;
  max-height: 100px;
  min-height: 100px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    &:hover {
      background: #555;
    }
  }

  @media (max-width: 768px) {
    align-items: center;
    order: 2;
  }
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

export const AnimatedSector = styled(Sector)`
  animation: ${pulseAnimation} 1.5s infinite;
  transform-origin: center;
`;

export const ResponsiveSpan = styled.span`
  font-weight: 500;
  font-size: 12px;
  @media (max-width: 1024px) {
    font-size: 10px;
  }
  @media (max-width: 768px) {
    font-size: 9px;
  }
  @media (max-width: 480px) {
    font-size: 8px;
  }
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 5px;
  color: ${(props) => props.color};

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

export const LegendText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const ColorIndicator = styled.span`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 10px;
`;
