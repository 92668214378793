import { Tooltip } from 'antd';
import { adquirenteNomeParaImagem, bancoImagens } from '../../styles/globalInterfaces';

export interface CollapseFilterProps {
  isCollapseOpen: boolean;
  setIsCollapseOpen: (isOpen: boolean) => void;
  setShowAcquirer: (show: boolean) => void;
  showAcquirer: boolean;
  handleAdquirenteSelect: (adquirenteName: any) => void;
  handleBancoSelect: (bancoName: any) => void;
  activeFilter: any;
  adquirentes: any[];
  bancos: any[];
}

export const AdquirentesList: React.FC<any> = ({ onSelect, activeFilter, adquirentes }) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    {adquirentes.map((item: any) => (
      <div
        key={item.ADQUIRENTEID}
        style={{
          margin: '25px 0 25px 60px',
          cursor: 'pointer',
          borderRadius: 10,
          padding: 2,
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          boxShadow: activeFilter === item.ADQUIRENTENOME ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
          transform: activeFilter === item.ADQUIRENTENOME ? 'scale(1.5)' : 'scale(1.2)',
          backgroundColor: activeFilter === item.ADQUIRENTENOME ? '#252f64' : 'transparent',
        }}
        onClick={() => onSelect(item.ADQUIRENTENOME)}
      >
        <Tooltip title={item.ADQUIRENTENOME} placement="top">
          <img src={adquirenteNomeParaImagem[item.ADQUIRENTENOME.toLowerCase().replace(/\s/g, '')]} alt={item.ADQUIRENTENOME} style={{ width: 60, height: 60, borderRadius: 10 }} />
        </Tooltip>
      </div>
    ))}
  </div>
);
export const BancosList: React.FC<any> = ({ onSelect, activeFilter, bancos }) => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    {bancos.map((item: any) => (
      <div
        key={item.BANCOID}
        style={{
          margin: '25px 0 25px 60px',
          cursor: 'pointer',
          borderRadius: 10,
          padding: 2,
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          boxShadow: activeFilter === item.BANCONOME ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
          transform: activeFilter === item.BANCONOME ? 'scale(1.5)' : 'scale(1.2)',
          backgroundColor: activeFilter === item.BANCONOME ? '#252f64' : 'transparent',
        }}
        onClick={() => onSelect(item.BANCONOME)}
      >
        <img src={bancoImagens[item.BANCOID]} alt={item.name} style={{ width: 60, height: 60, borderRadius: 10, backgroundColor: 'transparent' }} />
      </div>
    ))}
  </div>
);
