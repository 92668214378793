import { Reducer } from 'redux';
import {
  CLEAR_VENDA_DATA,
  CLEAR_VENDA_SORT_ORDER,
  FETCH_VENDA_ANALYTICS,
  FETCH_VENDA_ANALYTICS_FAILURE,
  FETCH_VENDA_ANALYTICS_SUCCESS,
  FETCH_VENDA_AUDIT_ANALYTICS,
  FETCH_VENDA_AUDIT_ANALYTICS_FAILURE,
  FETCH_VENDA_AUDIT_ANALYTICS_SUCCESS,
  FETCH_VENDA_AUDITADA_PDF,
  FETCH_VENDA_AUDITADA_PDF_FAILURE,
  FETCH_VENDA_AUDITADA_PDF_SUCCESS,
  FETCH_VENDA_AUDITADA_XLS,
  FETCH_VENDA_AUDITADA_XLS_FAILURE,
  FETCH_VENDA_AUDITADA_XLS_SUCCESS,
  FETCH_VENDA_CHART_DATA_FAILURE,
  FETCH_VENDA_CHART_DATA_SUCCESS,
  FETCH_VENDA_DATA,
  FETCH_VENDA_FAILURE,
  FETCH_VENDA_PDF,
  FETCH_VENDA_PDF_FAILURE,
  FETCH_VENDA_PDF_SUCCESS,
  FETCH_VENDA_SUCCESS,
  FETCH_VENDA_TOTALS,
  FETCH_VENDA_TOTALS_FAILURE,
  FETCH_VENDA_TOTALS_SUCCESS,
  FETCH_VENDA_XLS,
  FETCH_VENDA_XLS_FAILURE,
  FETCH_VENDA_XLS_SUCCESS,
  SET_VENDA_SORT_ORDER,
} from './types';

export const vendaState = {
  data: [],
  vendaTotals: {},
  vendaChartData: [],
  vendaAnalytics: [],
  vendaAuditAnalytics: [],
  loadingTotals: false,
  loading: false,
  loadingAnalytics: false,
  loadingVendaExport: false,
  loadingVendaExportAudit: false,
  error: null,
  errorTotals: null,
  sortField: null,
  sortOrder: null,
};

export const reducer: Reducer<any, any> = (state = vendaState, action: any) => {
  switch (action.type) {
    case SET_VENDA_SORT_ORDER:
      return {
        ...state,
        sortField: action.payload.field,
        sortOrder: action.payload.order,
      };
    case CLEAR_VENDA_SORT_ORDER:
      return {
        ...vendaState,
      };
    case FETCH_VENDA_TOTALS:
      return { ...state, loadingTotals: true };
    case FETCH_VENDA_TOTALS_SUCCESS:
      return { ...state, loadingTotals: false, vendaTotals: action.payload || {} };
    case FETCH_VENDA_TOTALS_FAILURE:
      return { ...state, loadingTotals: false, errorTotals: action.payload };
    case FETCH_VENDA_CHART_DATA_SUCCESS:
      return { ...state, vendaChartData: action.payload || [] };
    case FETCH_VENDA_CHART_DATA_FAILURE:
      return { ...state, error: action.payload };
    case FETCH_VENDA_DATA:
      return { ...state, loading: true };
    case FETCH_VENDA_SUCCESS:
      return { ...state, loading: false, data: action.payload || [] };
    case FETCH_VENDA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case CLEAR_VENDA_DATA:
      return { ...vendaState };
    case FETCH_VENDA_PDF:
      return { ...state, loadingVendaExport: true };
    case FETCH_VENDA_PDF_SUCCESS:
      return { ...state, loadingVendaExport: false };
    case FETCH_VENDA_PDF_FAILURE:
      return { ...state, loadingVendaExport: false };
    case FETCH_VENDA_XLS:
      return { ...state, loadingVendaExport: true };
    case FETCH_VENDA_XLS_SUCCESS:
      return { ...state, loadingVendaExport: false };
    case FETCH_VENDA_XLS_FAILURE:
      return { ...state, loadingVendaExport: false };
    case FETCH_VENDA_AUDITADA_PDF:
      return { ...state, loadingVendaExportAudit: true };
    case FETCH_VENDA_AUDITADA_PDF_SUCCESS:
      return { ...state, loadingVendaExportAudit: false };
    case FETCH_VENDA_AUDITADA_PDF_FAILURE:
      return { ...state, loadingVendaExportAudit: false };
    case FETCH_VENDA_AUDITADA_XLS:
      return { ...state, loadingVendaExportAudit: true };
    case FETCH_VENDA_AUDITADA_XLS_SUCCESS:
      return { ...state, loadingVendaExportAudit: false };
    case FETCH_VENDA_AUDITADA_XLS_FAILURE:
      return { ...state, loadingVendaExportAudit: false };
    case FETCH_VENDA_AUDIT_ANALYTICS:
      return { ...state };
    case FETCH_VENDA_AUDIT_ANALYTICS_SUCCESS:
      return { ...state, vendaAuditAnalytics: action.payload || {} };
    case FETCH_VENDA_AUDIT_ANALYTICS_FAILURE:
      return { ...state, error: action.payload };
    case FETCH_VENDA_ANALYTICS:
      return { ...state, loadingAnalytics: true };
    case FETCH_VENDA_ANALYTICS_SUCCESS:
      return { ...state, loadingAnalytics: false, vendaAnalytics: action.payload || {} };
    case FETCH_VENDA_ANALYTICS_FAILURE:
      return { ...state, loadingAnalytics: false, error: action.payload };
    default:
      return state;
  }
};
