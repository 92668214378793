export const CLEAR_AGENDA_RECEBIMENTOS_DATA = 'CLEAR_AGENDA_RECEBIMENTOS_DATA';
export const FETCH_AGENDA_RECEBIMENTOS_TOTALS_SUCCESS = 'FETCH_AGENDA_RECEBIMENTOS_TOTALS_SUCCESS';
export const FETCH_AGENDA_RECEBIMENTOS_TOTALS_FAILURE = 'FETCH_AGENDA_RECEBIMENTOS_TOTALS_FAILURE';
export const FETCH_AGENDA_RECEBIMENTOS_TOTALS = 'FETCH_AGENDA_RECEBIMENTOS_TOTALS'
export const FETCH_AGENDA_RECEBIMENTOS_DATA = 'FETCH_AGENDA_RECEBIMENTOS_DATA'
export const FETCH_AGENDA_RECEBIMENTOS_SUCCESS = 'FETCH_AGENDA_RECEBIMENTOS_SUCCESS'
export const FETCH_AGENDA_RECEBIMENTOS_FAILURE = 'FETCH_AGENDA_RECEBIMENTOS_FAILURE'
export const FETCH_AGENDA_RECEBIMENTOS_CHART_DATA_SUCCESS = 'FETCH_AGENDA_RECEBIMENTOS_CHART_DATA_SUCCESS';
export const FETCH_AGENDA_RECEBIMENTOS_CHART_DATA_FAILURE = 'FETCH_AGENDA_RECEBIMENTOS_CHART_DATA_FAILURE';
export const FETCH_AGENDA_RECEBIMENTOS_PDF = 'FETCH_AGENDA_RECEBIMENTOS_PDF'
export const FETCH_AGENDA_RECEBIMENTOS_PDF_SUCCESS = 'FETCH_AGENDA_RECEBIMENTOS_PDF_SUCCESS'
export const FETCH_AGENDA_RECEBIMENTOS_PDF_FAILURE = 'FETCH_AGENDA_RECEBIMENTOS_PDF_FAILURE'
export const FETCH_AGENDA_RECEBIMENTOS_XLS = 'FETCH_AGENDA_RECEBIMENTOS_XLS'
export const FETCH_AGENDA_RECEBIMENTOS_XLS_SUCCESS = 'FETCH_AGENDA_RECEBIMENTOS_XLS_SUCCESS'
export const FETCH_AGENDA_RECEBIMENTOS_XLS_FAILURE = 'FETCH_AGENDA_RECEBIMENTOS_XLS_FAILURE'

