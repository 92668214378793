import { Card, Tag } from 'antd';
import styled, { CSSProperties, keyframes } from 'styled-components';

export const fadeInOut = keyframes`
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
`;

export const StyledTag = styled(Tag)`
  display: inline-flex;
  align-items: center;
  .alert-icon {
    margin-right: 4px;
    animation: ${fadeInOut} 2s infinite;
  }
`;

export const StyledSpan = styled.span`
  font-weight: bold;
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
`;


export const Container = styled.div`
  width: auto;
  padding: 0.5% 3% 1% 45px;
  margin-top: 20px;
  margin-left: 2.5%;
  margin-right: 2.5%;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 500px) {
    margin-top: 3.5%;
  }
`;

export const CustomScrollBanks = styled.div`
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const CardStyled = styled(Card)`
  height: 112px;
`;

export const cardStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '85%',
  width: '100%',
  marginTop: 10,
};

export const imageStyle: CSSProperties = {
  maxHeight: '50px',
  marginTop: '10px',
  marginBottom: '10px',
  borderRadius: '10px',
};
