import { Reducer } from 'redux';
import { RECOVER_PASSWORD, RECOVER_PASSWORD_SUCCESS, RESET_PASSWORD, RESET_PASSWORD_SUCCESS } from './types';

const initialState = {
  loading: false,
};

export const reducer: Reducer<any> = (state = initialState, action: any) => {
  switch (action.type) {
    case RECOVER_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESET_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
