import { Button } from 'antd';
import { ConfirmationDialog, ConfirmationText, ModalOverlay } from './styled';

const ModalConfirmLogout = ({
  confirmLogout,
  cancelLogout,
}: {
  onclose: () => void;
  confirmLogout: () => void;
  cancelLogout: () => void;
}) => {
  return (
    <ModalOverlay>
      <ConfirmationDialog>
        <ConfirmationText>
          Deseja realmente encerrar a <strong>sessão</strong>?
        </ConfirmationText>
        <Button onClick={confirmLogout}>Sim</Button> <Button onClick={cancelLogout}>Não</Button>
      </ConfirmationDialog>
    </ModalOverlay>
  );
};

export default ModalConfirmLogout;
