import { Router } from 'react-router-dom';
import history from '../services/history';
import { GlobalStyle } from '../styles/global';
import { Layout } from 'antd';
import Sidebar from '../components/Sidebar';
import { Content } from 'antd/es/layout/layout';
import Routes from '../routes';
import { useSelector } from 'react-redux';
import { RootState } from '../store/types';
import Navigation from '../components/Navigation';
import background from '../assets/global/background.svg';

const App: React.FC = () => {
  const isAuthenticated = useSelector((state: RootState) => state.auth);
  const user = isAuthenticated.user;

  return (
    <>
      <GlobalStyle />
      <Router history={history}>
        <Layout style={{ minHeight: '100vh' }}>
          {isAuthenticated.accessToken && <Sidebar user={user} />}
          <Layout
            style={{
              background: 'transparent',
              backgroundImage: `url(${background})`,
              backgroundSize: 'cover',
            }}
          >
            {isAuthenticated.accessToken && <Navigation user={user} />}
            <Content
              style={{
                padding: 0,
                margin: 0,
                minHeight: 0,
                border: 'none'
              }}
            >
              <Routes />
            </Content>
          </Layout>
        </Layout>
      </Router>
    </>
  );
};

export default App;
