import { CardStyled, StatCardStyled, ValueStyled } from './styled';

export const StatCard = ({ loadingData, title, value, icon }: { loadingData: boolean; title: string; value: string | number; icon?: JSX.Element }) => {
  return (
    <StatCardStyled>
      <CardStyled hoverable bodyStyle={{ padding: 0 }}>
        <ValueStyled loading={loadingData}>
          <h3>
            {icon && <span>{icon}</span>}
            {title}
          </h3>
          {value}
        </ValueStyled>
      </CardStyled>
    </StatCardStyled>
  );
};
