import styled from 'styled-components';
import { Collapse } from 'antd';

export const StyledCollapseFilter = styled(Collapse)`
  .ant-collapse-item {
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out;
  }

  .ant-collapse-item > .ant-collapse-header {
    background: linear-gradient(135deg, #252f64, #536390);
    color: white;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  }

  .ant-collapse-item > .ant-collapse-header:hover {
    background: linear-gradient(135deg, #536390, #252f64);
    color: #d1d1d1;
  }

  .ant-collapse-item.ant-collapse-item-active {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .ant-collapse-item > .ant-collapse-content {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
  }

  .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    color: white;
    transition: transform 0.3s ease-in-out;
    display: inline-block;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(1080deg);
    }
  }

  .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow {
    animation: spin 1s ease-out;
  }
`;
