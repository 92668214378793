import { all } from 'redux-saga/effects';
import { watchLogin } from './states/auth/sagas';
import { watchEmpresaSaga } from './states/empresa/sagas';
import { watchVendaSaga } from './states/venda/sagas';
import { watchPagamentoSaga } from './states/pagamento/sagas';
import { watchUsuarioSaga } from './states/usuario/sagas';
import { watchUsuarioRegraSaga } from './states/usuarioRegra/sagas';
import { watchRateRegisterSaga } from './states/gestao-taxa/sagas';
import { watchEstabelecimentoSaga } from './states/estabelecimento/sagas';
import { watchAdquirenteSaga } from './states/adquirente/sagas';
import { watchBandeiraSaga } from './states/bandeira/sagas';
import { watchModalidadeSaga } from './states/modalidade/sagas';
import { watchBancoSaga } from './states/banco/sagas';
import { watchNotificationSaga } from './states/notificacao/sagas';
import { watchAgendaRecebimentosSaga } from './states/agendaRecebimentos/sagas';
import { watchPagamentoConsolidadoSaga } from './states/pagamentoConsolidado/sagas';

export default function* rootSaga() {
  yield all([
    watchLogin(),
    watchBancoSaga(),
    watchAdquirenteSaga(),
    watchBandeiraSaga(),
    watchEmpresaSaga(),
    watchEstabelecimentoSaga(),
    watchVendaSaga(),
    watchPagamentoSaga(),
    watchUsuarioSaga(),
    watchUsuarioRegraSaga(),
    watchRateRegisterSaga(),
    watchModalidadeSaga(),
    watchNotificationSaga(),
    watchAgendaRecebimentosSaga(),
    watchPagamentoConsolidadoSaga(),
  ]);
}
