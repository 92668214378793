export const CLEAR_GESTAO_TAXA = 'CLEAR_GESTAO_TAXA';
export const FETCH_GESTAO_TAXA_DATA = 'FETCH_GESTAO_TAXA_DATA';
export const FETCH_GESTAO_TAXA_SUCCESS = 'FETCH_GESTAO_TAXA_SUCCESS';
export const FETCH_GESTAO_TAXA_FAILURE = 'FETCH_GESTAO_TAXA_FAILURE';
export const UPDATE_GESTAO_TAXA = 'UPDATE_GESTAO_TAXA';
export const UPDATE_GESTAO_TAXA_SUCCESS = 'UPDATE_GESTAO_TAXA_SUCCESS';
export const UPDATE_GESTAO_TAXA_FAILURE = 'UPDATE_GESTAO_TAXA_FAILURE';
export const CREATE_GESTAO_TAXA = 'CREATE_GESTAO_TAXA';
export const CREATE_GESTAO_TAXA_SUCCESS = 'CREATE_GESTAO_TAXA_SUCCESS';
export const CREATE_GESTAO_TAXA_FAILURE = 'CREATE_GESTAO_TAXA_FAILURE';
export const DELETE_GESTAO_TAXA = 'DELETE_GESTAO_TAXA';
export const DELETE_GESTAO_TAXA_SUCCESS = 'DELETE_GESTAO_TAXA_SUCCESS';
export const DELETE_GESTAO_TAXA_FAILURE = 'DELETE_GESTAO_TAXA_FAILURE';
