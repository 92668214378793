import { takeLatest, call, put } from 'redux-saga/effects';
import { modalidadeApi } from '../../target-api/api';
import { toast } from 'react-toastify';
import { fetchModalidadeSuccess } from './actions';
import { FETCH_MODALIDADE_DATA } from './types';

export function* fetchModalidadeSaga() {
  try {
    const data: any[] = yield call(modalidadeApi);
    if (data !== undefined) {
      yield put(fetchModalidadeSuccess(data));
    } else {
      toast.warn('Falha ao retornar lista de modalidades!');
    }
  } catch (error: any) {
    toast.error(error);
  }
}

export function* watchModalidadeSaga() {
  yield takeLatest(FETCH_MODALIDADE_DATA, fetchModalidadeSaga);
}
