import React, { useState, useEffect } from 'react';
import { Layout, Menu, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import card from '../../assets/login/logo-card-union.svg';
import vendaIcon from '../../assets/analytics/vendas.svg';
import pagamentoIcon from '../../assets/analytics/pagamentos.svg';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { AnalyticsIcon, AuditSalesIcon, DashboardIcon, FinancialConferenceIcon, RateIcon } from './styled';
import { SidebarProps } from './interfaces';
import PortalAjuda from '../PortalAjuda';
import SubMenu from 'antd/es/menu/SubMenu';

const { Sider } = Layout;

const Sidebar: React.FC<SidebarProps> = ({ user }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');

  const location = useLocation();

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 1000);
      if (window.innerWidth > 1000 && visible) {
        setVisible(false);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [visible]);

  useEffect(() => {
    const pathname = location.pathname;
    setSelectedMenuItem(pathname);
  }, [location]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onClose = () => {
    setVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const content = (
    <Menu 
      mode="inline" 
      selectedKeys={[selectedMenuItem]} 
      style={{ height: '100%' }} 
      onClick={isMobile ? () => setVisible(false) : undefined}>
      <img style={{ padding: 30 }} src={card} alt="Card Logo" />
      <Menu.Item key="/dashboard" icon={<DashboardIcon />}>
        <NavLink to="/dashboard">Dashboard</NavLink>
      </Menu.Item>
      <Menu.Item key="/venda" icon={<AuditSalesIcon />}>
        <NavLink to="/venda">Vendas</NavLink>
      </Menu.Item>
      <SubMenu key="gestao-pagamento" icon={<FinancialConferenceIcon />} title="Pagamentos">
        <Menu.Item key="/pagamento" icon={<img src={vendaIcon} alt="Pagamentos" style={{ width: 16, height: 16 }} />}>
          <NavLink to="/pagamento">Pagamentos</NavLink>
        </Menu.Item>
        <Menu.Item key="/agenda-recebimentos" icon={<img src={pagamentoIcon} alt="Recebíveis" style={{ width: 16, height: 16 }} />}>
          <NavLink to="/agenda-recebimentos">Recebíveis</NavLink>
        </Menu.Item>
        <Menu.Item key="/pagamento-consolidado" icon={<img src={pagamentoIcon} alt="Pagamento Consolidado" style={{ width: 16, height: 16 }} />}>
          <NavLink to="/pagamento-consolidado">Consolidados</NavLink>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="gestao-cliente" icon={<AnalyticsIcon />} title="Analytics">
        <Menu.Item key="/analise-vendas" icon={<img src={vendaIcon} alt="Vendas" style={{ width: 16, height: 16 }} />}>
          <NavLink to="/analise-vendas">Vendas</NavLink>
        </Menu.Item>
        <Menu.Item key="/analise-pagamentos" icon={<img src={pagamentoIcon} alt="Vendas" style={{ width: 16, height: 16 }} />}>
          <NavLink to="/analise-pagamentos">Pagamentos</NavLink>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="/gestao-taxa" icon={<RateIcon />}>
        <NavLink to="/gestao-taxa">Taxas</NavLink>
      </Menu.Item>
      <PortalAjuda user={user} />
    </Menu>
  );

  return (
    <>
      {isMobile && (
        <>
          <Drawer placement="left" closable={false} onClose={onClose} visible={visible} bodyStyle={{ padding: 0 }} width={isMobile ? 250 : undefined}>
            {content}
          </Drawer>
          <MenuOutlined onClick={showDrawer} style={{ padding: '10px', fontSize: '15px', lineHeight: '32px', cursor: 'pointer' }} />
        </>
      )}

      {!isMobile && (
        <Sider width={250} collapsed={collapsed} onCollapse={toggleCollapsed}>
          {content}
        </Sider>
      )}
    </>
  );
};

export default Sidebar;
