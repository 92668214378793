import React, { useState } from 'react';
import { TableComponentProps } from './interfaces';
import { StyledTable } from './styled';

const TableComponent: React.FC<TableComponentProps> = ({ data, columns, rowKeyId, rowRender, perPage, expand }) => {
  const [sortField, setSortField] = useState<any>('');
  const [sortOrder, setSortOrder] = useState<any>('');
  const [expandedKeys, setExpandedKeys] = useState<any>([]);

  const handleTableChange = (sorter: any) => {
    if (sorter.order) {
      setSortField(sorter.field);
      setSortOrder(sorter.order);
    } else {
      setSortField(undefined);
      setSortOrder(undefined);
    }
  };

  const handleExpand = (record: any) => {
    const key = record[rowKeyId];
    const keys = expandedKeys.includes(key) ? expandedKeys.filter((k: any) => k !== key) : [...expandedKeys, key];
    setExpandedKeys(keys);
  };

  const expandableConfig = expand
    ? {
        expandedRowRender: rowRender,
        expandedRowKeys: expandedKeys,
        onExpand: (_: any, record: any) => handleExpand(record),
      }
    : undefined;

  return (
    <StyledTable
      loading={false}
      size="small"
      expandable={expandableConfig}
      columns={columns}
      dataSource={data}
      rowKey={rowKeyId}
      showSorterTooltip={false}
      sortDirections={sortOrder ? ['ascend', 'descend'] : undefined}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ['5', '10'],
        defaultPageSize: perPage ? perPage : 10,
      }}
      onChange={handleTableChange}
      scroll={{ x: 'max-content' }}
      onRow={(record) => ({
        onClick: () => handleExpand(record),
        style: { cursor: 'pointer' },
      })}
      style={{ marginTop: 10 }}
    />
  );
};

export default TableComponent;
