import { takeLatest, call, put } from 'redux-saga/effects';
import { adquirenteApi } from '../../target-api/api';
import { toast } from 'react-toastify';
import { fetchAdquirenteSuccess } from './actions';
import { FETCH_ADQUIRENTE_DATA } from './types';

export function* fetchAdquirenteSaga() {
  try {
    const data: any[] = yield call(adquirenteApi);
    if (data !== undefined) {
      yield put(fetchAdquirenteSuccess(data));
    } else {
      toast.warn('Falha ao retornar lista de adquirentes!');
    }
  } catch (error: any) {
    toast.error(error);
  }
}

export function* watchAdquirenteSaga() {
  yield takeLatest(FETCH_ADQUIRENTE_DATA, fetchAdquirenteSaga);
}
