import { takeLatest, call, put } from 'redux-saga/effects';
import { getUserRuleApi } from '../../target-api/api';
import { message } from 'antd';
import { fetchUsuarioRuleSuccess, fetchUsuarioRuleFailure } from './actions';
import { FETCH_USUARIO_REGRA } from './types';

export function* fetchUsuarioRegraSaga() {
  try {
    const data: any[] = yield call(getUserRuleApi);
    if (data && data.length <= 0) {
      // message.warning('Nenhuma regra de usuário encontrada!');
    }
    yield put(fetchUsuarioRuleSuccess(data));
  } catch (error: any) {
    message.warning('Não foi possível obter a lista de regras do usuário!');
    yield put(fetchUsuarioRuleFailure());
  }
}

export function* watchUsuarioRegraSaga() {
  yield takeLatest(FETCH_USUARIO_REGRA, fetchUsuarioRegraSaga);
}
