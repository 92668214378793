import { Image, Select, Tooltip } from 'antd';
import { adquirenteNomeParaImagem, bandeiraNomeParaImagens, modalidades } from '../../../styles/globalInterfaces';
import { ColumnsType } from 'antd/es/table';
import { HourglassOutlined, SecurityScanOutlined } from '@ant-design/icons';

const { Option } = Select;

export const columnsGestaoTaxa = (): ColumnsType<any> => {
  const columns: ColumnsType<any> = [
    {
      title: 'Situação',
      dataIndex: 'STATUS_VIGENCIA',
      key: 'STATUS_VIGENCIA',
      render: (text: string, record: any) => {
        return (
          <div>
            <SecurityScanOutlined /> {text}
          </div>
        );
      },
    },
    {
      title: 'Vigência',
      dataIndex: 'DIAS_RESTANTES',
      key: 'DIAS_RESTANTES',
      render: (text: string, record: any) => {
        return (
          <div>
            <HourglassOutlined /> {text} dia(s)
          </div>
        );
      },
    },
    {
      title: 'Empresa',
      dataIndex: 'EMPRESANOME',
      key: 'EMPRESANOME',
    },
    {
      title: 'Estabelecimento',
      dataIndex: 'ESTABELECIMENTONUMERO',
      key: 'ESTABELECIMENTONUMERO',
      sorter: (a: any, b: any) => {
        return a.ESTABELECIMENTONUMERO - b.ESTABELECIMENTONUMERO;
      },
    },
    {
      title: 'Usuário',
      dataIndex: 'USUARIONOME',
      key: 'USUARIONOME',
    },
    {
      title: 'Adquirente',
      dataIndex: 'ADQUIRENTENOME',
      key: 'ADQUIRENTENOME',
      render: (text: string, record: any) => {
        const adquirenteName = record.ADQUIRENTENOME ?? '';
        return (
          <div>
            <Image preview={false} width={35} src={adquirenteNomeParaImagem[adquirenteName.toLowerCase().replace(/\s/g, '')]} alt={adquirenteName} />
          </div>
        );
      },
      sorter: (a: any, b: any) => {
        if (a.ADQUIRENTENOME && b.ADQUIRENTENOME) {
          return a.ADQUIRENTENOME.localeCompare(b.ADQUIRENTENOME);
        }
        return 0;
      },
    },
    {
      title: 'Bandeira',
      dataIndex: 'BANDEIRANOME',
      key: 'BANDEIRANOME',
      render: (text: string, record: any) => {
        const bandeiraName = (record.BANDEIRANOME ?? '').toLowerCase() === 'não informado' ? 'nao_informado' : (record.BANDEIRANOME ?? '').toLowerCase();
        return (
          <Tooltip title={record.BANDEIRANOME} placement="left">
            <div>
              <Image width={30} src={bandeiraNomeParaImagens[bandeiraName]} alt={record.BANDEIRANOME} preview={false} />
            </div>
          </Tooltip>
        );
      },
      sorter: (a: any, b: any) => {
        if (a.BANDEIRANOME && b.BANDEIRANOME) {
          return a.BANDEIRANOME.localeCompare(b.BANDEIRANOME);
        }
        return 0;
      },
    },
    {
      title: 'Taxa',
      dataIndex: 'GESTAOTAXAVALORTAXA',
      key: 'GESTAOTAXAVALORTAXA',
      render: (text: number) => <span>{text !== undefined && text.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %</span>,
      sorter: (a: any, b: any) => {
        return a.GESTAOTAXAVALORTAXA - b.GESTAOTAXAVALORTAXA;
      },
    },
    {
      title: 'Modalidade',
      dataIndex: 'MODALIDADENOME',
      key: 'MODALIDADENOME',
      render: (text: string, record: any) => <div>{record.MODALIDADENOME}</div>,
      filterDropdown: ({ setSelectedKeys, selectedKeys = [], confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Select allowClear placeholder="Selecione a modalidade" style={{ width: 200, marginBottom: 8, display: 'block' }} value={selectedKeys[0]} onChange={(value) => setSelectedKeys(value ? [value] : [])}>
            {modalidades.map((modalidade: any) => (
              <Option key={modalidade.id} value={modalidade.name}>
                {modalidade.nome}
              </Option>
            ))}
          </Select>
          <button type="button" onClick={() => confirm?.()} style={{ width: 90, marginRight: 8 }}>
            Buscar
          </button>
          <button type="button" onClick={() => clearFilters?.()} style={{ width: 90 }}>
            Limpar
          </button>
        </div>
      ),
      onFilter: (value: any, record) => {
        return value === record.MODALIDADENOME;
      },
      sorter: (a: any, b: any) => {
        if (a.MODALIDADENOME && b.MODALIDADENOME) {
          return a.MODALIDADENOME.localeCompare(b.MODALIDADENOME);
        }
        return 0;
      },
    },
    {
      title: 'Qtde. Parcela(as)',
      dataIndex: 'GESTAOTAXAPARCELA',
      key: 'GESTAOTAXAPARCELA',
    },
  ];
  return columns;
};
