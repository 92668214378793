import { Col, message, Row, Skeleton } from 'antd';
import AnimatedBanner from '../../components/AnimatedBanner';
import { StatCard } from '../../components/Charts/StatCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/types';
import { DollarCircleOutlined } from '@ant-design/icons';
import { DropDownContent } from '../../components/DropDownContent';
import { fetchPagamentoConsolidado, fetchPagamentoConsolidadoPdf, fetchPagamentoConsolidadoXls } from '../../store/states/pagamentoConsolidado/actions';
import CollapseFilter from '../../components/CollapseFilter';
import { useEffect, useState } from 'react';
import { mapFiltersAgendaRecebimentosForApi, mapFiltersPagamentosConsolidadosForApi } from '../../utils/validateFilter';
import TableComponent from '../../components/TablePaginationNoSubfIlter';
import { columnsPagamentoConsolidado } from './interfaces/columnsPagamentosConsolidados';
import { Container } from '../../styles/GlobalStyles';

function PagamentoConsolidadoScreen() {
  const dispatch = useDispatch();
  const [showAcquirer, setShowAcquirer] = useState(() => {
    const persistedState = localStorage.getItem('showAcquirerPagamentosConsolidados');
    return persistedState ? JSON.parse(persistedState) : true;
  });
  const [isCollapseOpen, setIsCollapseOpen] = useState(true);
  const [selectedAdquirente, setSelectedAdquirente] = useState(null);
  const [selectedBanco, setSelectedBanco] = useState(null);
  const [perPage] = useState(10);
  const [activeFilter, setActiveFilter] = useState(null);
  const [filters, setFilters] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  
  const bandeiras = useSelector((state: RootState) => state.bandeira.data);
  const adquirentes = useSelector((state: RootState) => state.adquirente.data);
  const bancos = useSelector((state: RootState) => state.banco.data);
  const loading = useSelector((state: RootState) => state.pagamentoConsolidado.loading);
  const pagamentosConsolidados = useSelector((state: RootState) => state.pagamentoConsolidado.data);
  const pagamentosConsolidadosFiltroData = useSelector((state: RootState) => state.pagamentoConsolidado.consolidadosFiltroData);
  const loadingConsolidadoExport = useSelector((state: RootState) => state.pagamentoConsolidado.loadingConsolidadoExport);
  const companyIDs = useSelector((state: RootState) => state.empresa.companyID);
  const startDate = useSelector((state: RootState) => state.datas.startDate);
  const endDate = useSelector((state: RootState) => state.datas.endDate);
  const [pagesTotal, setpagesTotal] = useState(0);
  
  const adquirentesAtivos = new Set(pagamentosConsolidadosFiltroData?.list?.map((data: any) => data.ADQUIRENTENOME) || []);
  const adquirentesFiltradas = adquirentes.filter((adquirente: any) => adquirentesAtivos.has(adquirente.ADQUIRENTENOME));

  const bancosAtivos = new Set(pagamentosConsolidadosFiltroData?.list?.map((data: any) => data.BANCONOME) || []);
  const bancosFiltrados = bancos.filter((banco: any) => bancosAtivos.has(banco.BANCONOME));

  const bandeirasAtivas = new Set(pagamentosConsolidadosFiltroData?.list?.map((data: any) => data.BANDEIRANOME) || []);
  const bandeirasFiltradas = bandeiras.filter((bandeira: any) => bandeirasAtivas.has(bandeira.BANDEIRANOME));

  const empresas = new Set(pagamentosConsolidadosFiltroData?.list?.map((data: any) => data.EMPRESANOME) || []);
  const contas = new Set(pagamentosConsolidadosFiltroData?.list?.map((data: any) => data.CONTABANCARIA) || []);

  useEffect(() => {
    setpagesTotal(pagamentosConsolidados.totalList);
  }, [pagamentosConsolidados]);

  useEffect(() => {
    localStorage.setItem('showAcquirerPagamentosConsolidados', JSON.stringify(showAcquirer));
  }, [showAcquirer]);

  const handleExportXls = () => {
    message.success('Gerando o relatório de recebíveis no formato .xlsx, aguarde...');
    dispatch(
      fetchPagamentoConsolidadoXls({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
      }),
    );
  };

  const handleExportPdf = () => {
    message.success('Gerando o relatório de recebíveis no formato .pdf, aguarde...');
    dispatch(
      fetchPagamentoConsolidadoPdf({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
      }),
    );
  };

  const handleAdquirenteSelect = (adquirenteName: any) => {
    if (selectedAdquirente === adquirenteName) {
      setSelectedAdquirente(null);
      setActiveFilter(null);
      fetchConsolidados(1, pageSize, 'PAGAMENTODATAPAGAMENTO', 'DESC', mapFiltersPagamentosConsolidadosForApi({ ADQUIRENTENOME: null }));
    } else {
      const newFilters = selectedAdquirente === adquirenteName ? {} : mapFiltersPagamentosConsolidadosForApi({ ADQUIRENTENOME: adquirenteName });
      setFilters(newFilters);
      fetchConsolidados(currentPage, pageSize, 'PAGAMENTODATAPAGAMENTO', 'DESC', newFilters);
      setSelectedAdquirente(adquirenteName);
      setActiveFilter(adquirenteName);
      setSelectedBanco(null);
    }
  };

  const handleBancoSelect = (bancoName: any) => {
    if (selectedBanco === bancoName) {
      setSelectedBanco(null);
      setActiveFilter(null);
      fetchConsolidados(1, pageSize, 'PAGAMENTODATAPAGAMENTO', 'DESC', mapFiltersAgendaRecebimentosForApi({ BANCONOME: null }));
    } else {
      const newFilters = selectedBanco === bancoName ? {} : mapFiltersAgendaRecebimentosForApi({ BANCONOME: bancoName });
      setFilters(newFilters);
      fetchConsolidados(currentPage, pageSize, 'PAGAMENTODATAPAGAMENTO', 'DESC', newFilters);
      setSelectedBanco(bancoName);
      setActiveFilter(bancoName);
      setSelectedAdquirente(null);
    }
  };

  const fetchConsolidados = (page: number, pageSize: number, sortField: any, sortOrder: any, filters: any) => {
    const order = sortOrder !== undefined && sortOrder === 'ascend' ? 'ASC' : 'DESC';
    const apiFilters = mapFiltersPagamentosConsolidadosForApi(filters);
    dispatch(
      fetchPagamentoConsolidado({
        company_id: companyIDs,
        start_date: startDate,
        end_date: endDate,
        page,
        pageSize,
        filters: apiFilters,
        sortField,
        sortOrder: order,
      }),
    );
  };

  const handlePageChange = (page: number = 1, pageSize: number = 10, sortField: string = 'PAGAMENTODATAPAGAMENTO', sortOrder: string = 'DESC', filters: any) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchConsolidados(page, pageSize, sortField, sortOrder, filters);
  };

  return (
    <AnimatedBanner>
      <Container>
        <>
          <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
            <Col flex="5">
              <StatCard
                loadingData={loading}
                title="Bruto"
                value={(pagamentosConsolidados.totalValorBruto || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
            <Col flex="5">
              <StatCard
                loadingData={loading}
                title="Líquido"
                value={(pagamentosConsolidados.totalValorLiquido || 0).toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
                icon={<DollarCircleOutlined />}
              />
            </Col>
          </Row>

          {loading ? (
            <Skeleton active />
          ) : (
            <>
              {pagamentosConsolidados.list && <DropDownContent loadingExport={loadingConsolidadoExport} transacoes={pagamentosConsolidados} handleExportXls={handleExportXls} handleExportPdf={handleExportPdf} />}
              <CollapseFilter
                isCollapseOpen={isCollapseOpen}
                setIsCollapseOpen={setIsCollapseOpen}
                setShowAcquirer={setShowAcquirer}
                showAcquirer={showAcquirer}
                handleAdquirenteSelect={handleAdquirenteSelect}
                handleBancoSelect={handleBancoSelect}
                activeFilter={activeFilter}
                adquirentes={adquirentesFiltradas}
                bancos={bancosFiltrados}
              />
            </>
          )}
          <TableComponent
            loadingData={loading}
            data={pagamentosConsolidados.list && pagamentosConsolidados.list.length > 0 ? pagamentosConsolidados.list : []}
            columns={columnsPagamentoConsolidado(false, Array.from(adquirentesFiltradas), Array.from(bandeirasFiltradas), Array.from(empresas), Array.from(contas))}
            rowKeyId="PAGAMENTOID"
            rowRender={0}
            perPage={perPage}
            currentPage={currentPage}
            totalPages={pagesTotal}
            onChangePage={handlePageChange}
            expand={true}
            externalFilters={filters}
            totalBrutoSubFiltro={0}
            totalLiquidoSubFiltro={0}
          />
        </>
      </Container>
    </AnimatedBanner>
  );
}

export default PagamentoConsolidadoScreen;
