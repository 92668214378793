export const SET_VENDA_SORT_ORDER = 'SET_VENDA_SORT_ORDER';
export const CLEAR_VENDA_SORT_ORDER = 'CLEAR_VENDA_SORT_ORDER';
export const CLEAR_VENDA_DATA = 'CLEAR_VENDA_DATA';
export const FETCH_VENDA_AUDIT_ANALYTICS = 'FETCH_VENDA_AUDIT_ANALYTICS';
export const FETCH_VENDA_AUDIT_ANALYTICS_SUCCESS = 'FETCH_VENDA_AUDIT_ANALYTICS_SUCCESS';
export const FETCH_VENDA_AUDIT_ANALYTICS_FAILURE = 'FETCH_VENDA_AUDIT_ANALYTICS_FAILURE';
export const FETCH_VENDA_ANALYTICS = 'FETCH_VENDA_ANALYTICS';
export const FETCH_VENDA_ANALYTICS_SUCCESS = 'FETCH_VENDA_ANALYTICS_SUCCESS';
export const FETCH_VENDA_ANALYTICS_FAILURE = 'FETCH_VENDA_ANALYTICS_FAILURE';
export const FETCH_VENDA_CHART_DATA = 'FETCH_VENDA_CHART_DATA';
export const FETCH_VENDA_CHART_DATA_SUCCESS = 'FETCH_VENDA_CHART_DATA_SUCCESS';
export const FETCH_VENDA_CHART_DATA_FAILURE = 'FETCH_VENDA_CHART_DATA_FAILURE';
export const FETCH_VENDA_TOTALS = 'FETCH_VENDA_TOTALS';
export const FETCH_VENDA_TOTALS_SUCCESS = 'FETCH_VENDA_TOTALS_SUCCESS';
export const FETCH_VENDA_TOTALS_FAILURE = 'FETCH_VENDA_TOTALS_FAILURE';
export const FETCH_VENDA_DATA = 'FETCH_VENDA_DATA';
export const FETCH_VENDA_SUCCESS = 'FETCH_VENDA_SUCCESS';
export const FETCH_VENDA_FAILURE = 'FETCH_VENDA_FAILURE';
export const FETCH_VENDA_PDF = 'FETCH_VENDA_PDF';
export const FETCH_VENDA_PDF_SUCCESS = 'FETCH_VENDA_PDF_SUCCESS';
export const FETCH_VENDA_PDF_FAILURE = 'FETCH_VENDA_PDF_FAILURE';
export const FETCH_VENDA_XLS = 'FETCH_VENDA_XLS';
export const FETCH_VENDA_XLS_SUCCESS = 'FETCH_VENDA_XLS_SUCCESS';
export const FETCH_VENDA_XLS_FAILURE = 'FETCH_VENDA_XLS_FAILURE';
export const FETCH_VENDA_AUDITADA_PDF = 'FETCH_VENDA_AUDITADA_PDF';
export const FETCH_VENDA_AUDITADA_PDF_SUCCESS = 'FETCH_VENDA_AUDITADA_PDF_SUCCESS';
export const FETCH_VENDA_AUDITADA_PDF_FAILURE = 'FETCH_VENDA_AUDITADA_PDF_FAILURE';
export const FETCH_VENDA_AUDITADA_XLS = 'FETCH_VENDA_AUDITADA_XLS';
export const FETCH_VENDA_AUDITADA_XLS_SUCCESS = 'FETCH_VENDA_AUDITADA_XLS_SUCCESS';
export const FETCH_VENDA_AUDITADA_XLS_FAILURE = 'FETCH_VENDA_AUDITADA_XLS_FAILURE';
