import {
  CaretUpOutlined,
  CarryOutOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  FieldNumberOutlined,
  FileProtectOutlined,
  LoadingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Card, Col, Row, DatePicker, Form, InputNumber, Modal, Button, Input } from 'antd';
import moment from 'moment';
import { StyledModalContent } from './styled';

export const ModalGestaoTaxasActions = ({
  record,
  handleDelete,
  handleEdit,
}: {
  record: any;
  handleDelete: any;
  handleEdit: any;
}) => {
  const [form] = Form.useForm();
  const showDeleteConfirm = () => {
    Modal.confirm({
      title: 'Tem certeza que deseja excluir esta taxa?',
      content: 'Esta ação é irreversível',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      onOk() {
        handleDelete(record.GESTAOTAXAID);
      },
    });
  };
  const showEditConfirm = () => {
    Modal.confirm({
      title: 'Editar taxa de contrato',
      icon: null,
      content: (
        <StyledModalContent>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              MODALIDADENOME: record.MODALIDADENOME,
              BANDEIRANOME: record.BANDEIRANOME,
              ADQUIRENTENOME: record.ADQUIRENTENOME,
              EMPRESANOME: record.EMPRESANOME,
              ESTABELECIMENTONUMERO: record.ESTABELECIMENTONUMERO,
              GESTAOTAXAVIGENCIAINICIO: moment(record.GESTAOTAXAVIGENCIAINICIO).utc(),
              GESTAOTAXAVIGENCIAFIM: moment(record.GESTAOTAXAVIGENCIAFIM).utc(),
              GESTAOTAXAVALORTAXA: record.GESTAOTAXAVALORTAXA,
            }}
          >
            <Row gutter={16} style={{ marginTop: 5, marginBottom: 10 }}>
              <Col span={24}>
                <Form.Item label="Empresa" name="EMPRESANOME">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Estabelecimento" name="ESTABELECIMENTONUMERO">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Adquirente" name="ADQUIRENTENOME">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Modalidade" name="MODALIDADENOME">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Bandeira" name="BANDEIRANOME">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="GESTAOTAXAVIGENCIAINICIO"
                  label="Data Início Vigência"
                  initialValue={moment(record.GESTAOTAXAVIGENCIAINICIO)}
                >
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="GESTAOTAXAVIGENCIAFIM"
                  label="Data Fim Vigência"
                  initialValue={moment(record.GESTAOTAXAVIGENCIAFIM)}
                >
                  <DatePicker format="DD/MM/YYYY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="% Taxa" name="GESTAOTAXAVALORTAXA">
                  <InputNumber
                    formatter={(value) => `${value ?? ''}%`}
                    parser={(value) => value?.replace(/%\s?|(,*)/g, '') ?? ''}
                    precision={3}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </StyledModalContent>
      ),
      okText: 'Salvar',
      cancelText: 'Cancelar',
      onOk() {
        const updatedData = form.getFieldsValue();
        handleEdit(record.GESTAOTAXAID, updatedData);
      },
      okButtonProps: {
        style: { backgroundColor: '#252f64' },
      },
    });
  };
  return (
    <Card bordered={false} style={{ marginTop: 16 }}>
      <div style={{ marginBottom: 15 }}>
        <CaretUpOutlined style={{ marginRight: 10 }} />
        <strong>Registro de taxa por Contrato</strong>
      </div>
      <Row gutter={16}>
        <Col span={6}>
          <div>
            <FileProtectOutlined style={{ marginRight: 10 }} />
            <span>Data de cadastro: </span>
            <strong>{moment(record.GESTAOTAXACREATEDAT, "ddd, DD MMM YYYY HH:mm:ss [GMT]").local().format('DD/MM/YYYY HH:mm:ss')}</strong>
          </div>
          <div>
            <LoadingOutlined style={{ marginRight: 10 }} />
            <span>Última alteração: </span>
            <strong>{moment(record.GESTAOTAXAUPDATEDAT, "ddd, DD MMM YYYY HH:mm:ss [GMT]").local().format('DD/MM/YYYY HH:mm:ss')}</strong>
          </div>
          <div>
            <UserOutlined style={{ marginRight: 10 }} />
            <span>Realizado por: </span>
            <strong>{record.USUARIONOME}</strong>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <CarryOutOutlined style={{ marginRight: 10 }} />
            <span>Data Início Vigência: </span>
            <strong>{moment(record.GESTAOTAXAVIGENCIAINICIO, "ddd, DD MMM YYYY HH:mm:ss [GMT]").format('DD/MM/YYYY')}</strong>
          </div>
          <div>
            <ClockCircleOutlined style={{ marginRight: 10 }} />
            <span>Data Fim Vigência: </span>
            <strong>{moment(record.GESTAOTAXAVIGENCIAFIM, "ddd, DD MMM YYYY HH:mm:ss [GMT]").format('DD/MM/YYYY')}</strong>
          </div>
        </Col>
        <Col span={6}>
          <div>
            <EnvironmentOutlined style={{ marginRight: 10 }} />
            <span>Empresa: </span>
            <strong>{record.EMPRESANOME}</strong>
          </div>
          <div>
            <FieldNumberOutlined style={{ marginRight: 10 }} />
            <span>Estabelecimento: </span>
            <strong>{record.ESTABELECIMENTONUMERO}</strong>
          </div>
        </Col>
      </Row>
      <div style={{ marginTop: 20, textAlign: 'right' }}>
        <Button style={{ backgroundColor: '#252f64', marginRight: 5 }} type="primary" onClick={showEditConfirm}>
          Editar
        </Button>
        <Button style={{ backgroundColor: '#803525' }} type="primary" onClick={showDeleteConfirm}>
          Excluir
        </Button>
      </div>
    </Card>
  );
};
