import React from 'react';
import { Card, Form, Modal, Input, Tag } from 'antd';
import { ModalDisplay } from './styled';

interface ModalProps {
  isOpen: boolean;
  user: any;
  usuarioRegra: any;
  onClose: () => void;
}

const ModalPerfil: React.FC<ModalProps> = ({ isOpen, user, usuarioRegra, onClose }) => {
  const cardStyle = {
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  };

  const tagStyle = {
    margin: '5px',
    color: '#108ee9',
    border: '1px solid #108ee9',
  };

  return (
    <ModalDisplay>
      <Modal visible={isOpen} onCancel={onClose} footer={null} width={500} title="Perfil de Usuário">
        <Card bordered={false} style={cardStyle}>
          <Form layout="vertical">
            <Form.Item label="Nome">
              <Input value={user.USUARIONOME} readOnly />
            </Form.Item>
            <Form.Item label="Nome de Usuário">
              <Input value={user.USUARIOUSERNOME} readOnly />
            </Form.Item>
            <Form.Item label="Email">
              <Input value={user.USUARIOEMAIL} readOnly />
            </Form.Item>
            <Form.Item label="Permissões">
              {usuarioRegra &&
                usuarioRegra.data.map((item: any) => (
                  <Tag key={item.USUARIOREGRID} style={tagStyle}>
                    {item.USUARIOREGRANAME}
                  </Tag>
                ))}
            </Form.Item>
          </Form>
        </Card>
      </Modal>
    </ModalDisplay>
  );
};

export default ModalPerfil;
