import { styled } from 'styled-components';

export const StyledButton = styled.button`
  background-color: #252f64;
  font-family: 'Poppins', sans-serif;
  color: white;
  border: none;
  padding: 8px 15px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #3c4780;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
  &:active {
    transform: translateY(1px);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }
  &:focus {
    outline: none;
  }
`;

export const StyledRangePickerContainer = styled.div`
  @media (max-width: 576px) {
    .ant-picker-panels {
      flex-direction: column !important;
      font-size: 10px;
    }
  }
`;
