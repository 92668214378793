export const formatDateShort = (dateString: any) => {
  const year = dateString.substring(4, 6);
  const month = dateString.substring(2, 4);
  const day = dateString.substring(0, 2);

  return `${day}/${month}/${year}`;
};

export const formatTime = (timeString: string) => {
  const hour = timeString.substring(0, 2);
  const minute = timeString.substring(2, 4);
  const second = timeString.substring(4, 6);

  return `${hour}:${minute}:${second}`;
};

export function formatDate(dateString: any) {
  const parts = dateString.split('/');
  return `${parts[2]}-${parts[1]}-${parts[0]}`;
}
