import { Menu, Dropdown, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import styled, { keyframes } from 'styled-components';
import { DropDownContentProps } from './interfaces';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledDropdown = styled(Dropdown)`
  .ant-dropdown-menu {
    background: linear-gradient(135deg, #252f64, #536390);
    color: white;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    animation: ${fadeIn} 0.5s ease-out;
  }

  .ant-dropdown-menu-item {
    transition: background 0.3s, color 0.3s, transform 0.3s;
  }

  .ant-dropdown-menu-item:hover {
    background: linear-gradient(135deg, #536390, #252f64);
    color: #d1d1d1;
    transform: scale(1.05);
  }

  .ant-dropdown-menu-item:active {
    background: linear-gradient(135deg, #252f64, #536390);
  }
`;

const StyledButton = styled(Button)`
  background: linear-gradient(135deg, #536390, #252f64);
  color: white;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  &:hover,
  &:focus {
    background: linear-gradient(135deg, #252f64, #536390) !important;
    color: #f0f0f0 !important;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  .anticon {
    margin-right: 8px;
  }
`;

export function DropDownContent({ loadingExport, transacoes, handleExportXls, handleExportPdf }: DropDownContentProps) {
  const exportMenu = (
    <Menu>
      <Menu.Item key="xlsx" onClick={handleExportXls}>
        Exportar no formato .xlsx
      </Menu.Item>
      <Menu.Item key="pdf" onClick={handleExportPdf}>
        Exportar no formato .pdf
      </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }}>
      <StyledDropdown overlay={exportMenu} trigger={['click']} placement="bottomRight">
        <StyledButton icon={<DownloadOutlined />} loading={loadingExport} disabled={transacoes.length === 0}>
          Exportar Relatório
        </StyledButton>
      </StyledDropdown>
    </div>
  );
}
