import styled from 'styled-components';
import { Select } from 'antd';

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    border-radius: 8px !important;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;

    .ant-select-selection-overflow {
      display: flex;
      flex-wrap: nowrap;
    }

    .ant-select-selection-overflow-item {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;
