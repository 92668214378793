import { 
    CLEAR_PAGAMENTOS_CONSOLIDADOS_DATA,
    FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA_FAILURE,
    FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA_SUCCESS,
    FETCH_PAGAMENTOS_CONSOLIDADOS_DATA,
    FETCH_PAGAMENTOS_CONSOLIDADOS_FAILURE, 
    FETCH_PAGAMENTOS_CONSOLIDADOS_PDF, 
    FETCH_PAGAMENTOS_CONSOLIDADOS_PDF_FAILURE, 
    FETCH_PAGAMENTOS_CONSOLIDADOS_PDF_SUCCESS, 
    FETCH_PAGAMENTOS_CONSOLIDADOS_SUCCESS, 
    FETCH_PAGAMENTOS_CONSOLIDADOS_XLS, 
    FETCH_PAGAMENTOS_CONSOLIDADOS_XLS_FAILURE, 
    FETCH_PAGAMENTOS_CONSOLIDADOS_XLS_SUCCESS, 
    FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA} from "./types";


export const clearPagamentoConsolidadoData = () => ({
    type: CLEAR_PAGAMENTOS_CONSOLIDADOS_DATA,
});


export const fetchPagamentoConsolidado = (payload: { company_id: number | number[]; start_date: any; end_date: any; page: number; pageSize: number; filters?: Record<string, any>; sortField: any; sortOrder: any }) => ({
    type: FETCH_PAGAMENTOS_CONSOLIDADOS_DATA,
    payload,
});

export const fetchPagamentoConsolidadoSuccess = (data: any) => ({
    type: FETCH_PAGAMENTOS_CONSOLIDADOS_SUCCESS,
    payload: data,
});

export const fetchPagamentoConsolidadoFailure = (error: any) => ({
    type: FETCH_PAGAMENTOS_CONSOLIDADOS_FAILURE,
    payload: error,
});

export const fetchPagamentoConsolidadoFiltro = (payload: { company_id: number | number[]; start_date: any; end_date: any; page: number; pageSize: number; sortField: any; sortOrder: any }) => ({
    type: FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA,
    payload,
});

export const fetchPagamentoConsolidadoFilterDataSuccess = (data: any) => ({
    type: FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA_SUCCESS,
    payload: data,
  });

export const fetchPagamentoConsolidadoFilterDataFailure = (error: any) => ({
    type: FETCH_PAGAMENTOS_CONSOLIDADOS_FILTER_DATA_FAILURE,
    payload: error,
});

export const fetchPagamentoConsolidadoPdf = (payload: { company_id: number | number[]; start_date: string; end_date: string }) => ({
    type: FETCH_PAGAMENTOS_CONSOLIDADOS_PDF,
    payload,
  });
  
export const fetchPagamentoConsolidadoPdfSuccess = () => ({
    type: FETCH_PAGAMENTOS_CONSOLIDADOS_PDF_SUCCESS,
});

export const fetchPagamentoConsolidadoPdfFailure = (error: any) => ({
    type: FETCH_PAGAMENTOS_CONSOLIDADOS_PDF_FAILURE,
    payload: error,
});

export const fetchPagamentoConsolidadoXls = (payload: { company_id: number | number[]; start_date: string; end_date: string }) => ({
    type: FETCH_PAGAMENTOS_CONSOLIDADOS_XLS,
    payload,
});

export const fetchPagamentoConsolidadoXlsSuccess = () => ({
    type: FETCH_PAGAMENTOS_CONSOLIDADOS_XLS_SUCCESS,
});

export const fetchPagamentoConsolidadoXlsFailure = (error: any) => ({
    type: FETCH_PAGAMENTOS_CONSOLIDADOS_XLS_FAILURE,
    payload: error,
});