import styled from 'styled-components';

export const StyledModalContent = styled.div`
  .ant-form-item {
    margin-bottom: 24px;
  }
  .ant-picker {
    width: 100%;
    .ant-picker-header .ant-picker-header-view {
      pointer-events: none;
    }
  }
  .ant-input-number {
    width: 100%;
  }
  @media (max-width: 768px) {
    .ant-form-item {
      margin-bottom: 16px;
    }
    .ant-btn {
      margin-bottom: 8px;
    }
  }
`;
