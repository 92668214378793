import { Card } from 'antd';
import { styled } from 'styled-components';

export const StyledCard = styled(Card)`
  cursor: pointer;
  .ant-card-head {
    background: linear-gradient(135deg, #252f64, #536390);
    color: white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 8px;
  }
  .ant-card-head-title {
    display: flex;
    align-items: left;
    justify-content: left;
    width: 100%;
  }
`;
