import React, { useState } from 'react';
import { TablePaginationNoFIlterProps } from './interfaces';
import { StyledTable } from './styled';

const TablePaginationNoFilter: React.FC<TablePaginationNoFIlterProps> = ({ loadingData, data, columns, rowKeyId, rowRender, perPage, expand, currentPage, totalPages, onChangePage, externalFilters, totalBrutoSubFiltro, totalLiquidoSubFiltro }) => {
  const [sortField, setSortField] = useState<any>('');
  const [sortOrder, setSortOrder] = useState<any>('');
  const [currentFilters, setCurrentFilters] = useState<any>({});
  const [expandedKeys, setExpandedKeys] = useState<any>([]);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { field, order } = sorter;
    setSortField(field);
    setSortOrder(order);
    const combinedFilters = { ...externalFilters, ...filters };
    setCurrentFilters(combinedFilters);
    onChangePage(pagination.current, pagination.pageSize, field, order, combinedFilters);
  };

  const handleExpand = (record: any) => {
    const key = record[rowKeyId];
    const keys = expandedKeys.includes(key) ? expandedKeys.filter((k: any) => k !== key) : [...expandedKeys, key];
    setExpandedKeys(keys);
  };

  const expandableConfig = expand
    ? {
        expandedRowRender: rowRender,
        expandedRowKeys: expandedKeys,
        onExpand: (_: any, record: any) => handleExpand(record),
      }
    : undefined;

  return (
    <>
      <StyledTable
        loading={loadingData}
        size="small"
        expandable={expandableConfig}
        columns={columns}
        dataSource={data}
        rowKey={rowKeyId}
        showSorterTooltip={false}
        sortDirections={sortOrder ? ['ascend', 'descend'] : undefined}
        pagination={{
          total: totalPages,
          current: currentPage,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '15'],
          defaultPageSize: perPage ? perPage : 10,
          onChange: (page: any, pageSize: any) => onChangePage(page, pageSize, sortField, sortOrder, currentFilters),
        }}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
        onRow={(record) => ({
          onClick: () => handleExpand(record),
          style: { cursor: 'pointer' },
        })}
        style={{ marginTop: 10 }}
      />
    </>
  );
};

export default TablePaginationNoFilter;
