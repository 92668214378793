import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Image, Col, Typography, Row } from 'antd';
import groupFinlyImage from '../../assets/landing-page/group-finly.png';
import Groupimagecard from '../../assets/landing-page/Groupimagecard (2).png';
import FrameCard from '../../assets/landing-page/frameCard.png';
import GroupFinlyImageFrom from '../../assets/landing-page/group-finly-image (3).png';
import GroupFinlyCard from '../../assets/landing-page/group-finlycard.png';
import Backgroundfooter from '../../assets/landing-page/backgroundfooter.png';
import ModalRateProposal from '../../components/Modals/ModalProposal';
import {
  Content,
  LoginButton,
  StyledGroupFinlyImage,
  LoginProposal,
  LeftScreen,
  ContainerPositionScreen,
  PositiontFooter,
} from './styled';

const { Paragraph } = Typography;

const LandingPage: React.FC = () => {
  const [IsModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const history = useHistory();
  const redirectionPageLogin = () => {
    history.push('/login');
  };

  return (
    <Content>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ marginTop: '5%', left: '30%' }}>
        <LoginButton type="primary" shape="default" onClick={redirectionPageLogin}>
          Entrar
        </LoginButton>
      </Col>

      <Row style={{ marginLeft: '32%', marginTop: '4% ' }}>
        <StyledGroupFinlyImage>
          <Image preview={false} alt="Finly Tech" src={groupFinlyImage} />
        </StyledGroupFinlyImage>
      </Row>

      <LeftScreen>
        <ContainerPositionScreen style={{}}>
          <Col
            style={{
              marginLeft: '10%',
            }}
          >
            <Paragraph
              style={{
                lineHeight: '119.5%',
                position: 'relative',
                color: '#C9E437',
                fontFamily: 'Poppins',
                fontSize: '300%',
                marginTop: '-35%',
              }}
            >
              Conciliação de <br />
              operações de cartões
              <br />
              de crédito e débito
            </Paragraph>
          </Col>
        </ContainerPositionScreen>
        <>
          <Row gutter={[192, 192]} style={{ marginLeft: '10%' }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} order={1}>
              <Paragraph
                style={{
                  color: '#22326E',
                  fontFamily: 'Poppins',
                  fontSize: '30px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '119.5%',
                  marginTop: '70%',
                }}
              >
                <>O que é Finly Card?</>
              </Paragraph>

              <Paragraph
                style={{
                  position: 'relative',
                  color: '#797979',
                  fontFamily: 'Poppins',
                  fontSize: '13px',
                }}
              >
                <>
                  O FINLY CARD é um sistema especializado em auditoria para as <br />
                  operações das vendas de cartão de crédito, débito e vouchers dos
                  nossos clientes.<br /> A solução também oferece a gestão e conciliação automatizada em tempo real.
                </>
              </Paragraph>
              <Paragraph
                style={{
                  color: '#22326E',
                  fontFamily: 'Poppins',
                  fontSize: '30px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '119.5%',
                }}
              >
                <>Como conciliamos?</>
              </Paragraph>
              <Paragraph
                style={{
                  color: '#797979',
                  fontFamily: 'Poppins',
                  fontSize: '13px',
                }}
              >
                <>
                  Com os dados das operadoras, nós conciliamos suas vendas de
                  forma automática. <br />Utilizamos o mais alto nível de tecnologia em processamentos e segurança, para que seus <br />
                  dados ﬁquem totalmente seguros.
                </>
              </Paragraph>
              <Row>
                <Image
                  preview={false}
                  alt="Logo"
                  src={FrameCard}
                  style={{ width: '80%', marginLeft: '-10%', marginTop: '0% ' }}
                />{' '}
              </Row>
              <Paragraph
                style={{
                  color: '#22326E',
                  fontFamily: 'Poppins',
                  fontSize: '30px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '119.5%',
                }}
              >
                <>Auditoria de Taxas</>
              </Paragraph>

              <Paragraph
                style={{
                  color: '#797979',
                  fontFamily: 'Poppins',
                  fontSize: '13px',
                }}
              >
                <>
                  A auditoria de taxas é um processo crucial para garantir a precisão das <br />
                  transações financeiras do seu negócio. Com o nosso sistema, você pode <br />
                  cadastrar o contrato do cliente, definindo modalidades, cartões, bandeiras, <br />
                  taxas e períodos de vigência. Após o cadastro, nossa ferramenta analisa <br />
                  as transações para identificar se as taxas aplicadas estão em conformidade <br />
                  com o contrato. No módulo de analytics, você pode visualizar as taxas <br />
                  auditadas, destacando as que estão corretas e alertando sobre possíveis <br />
                  erros ou fraudes. Garanta a integridade financeira do seu negócio com <br />
                  nossa auditoria de taxas.
                </>
              </Paragraph>

              <Paragraph
                style={{
                  color: '#22326E',
                  fontFamily: 'Poppins',
                  fontSize: '30px',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '119.5%',
                }}
              >
                <>Conciliação bancária</>
              </Paragraph>
              <Paragraph
                style={{
                  color: '#797979',
                  fontFamily: 'Poppins',
                  fontSize: '13px',
                }}
              >
                <>
                  Você no controle de suas vendas, conferindo se todos os <br />
                  pagamentos realizados pelas operadoras foram autorizados <br />
                  corretamente em sua conta bancária.
                </>
              </Paragraph>
              <Row>
                <Image
                  preview={false}
                  alt="Logo"
                  src={GroupFinlyCard}
                  style={{ width: '80%', marginLeft: '-10%', marginTop: '0% ' }}
                />{' '}
              </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} order={2}>
              <>
                <Image
                  preview={false}
                  alt="Logo"
                  src={Groupimagecard}
                  style={{ width: '80%', marginLeft: '-10%', marginTop: '65% ' }}
                />{' '}
                <Paragraph
                  style={{
                    color: '#22326E',
                    fontFamily: 'Poppins',
                    fontSize: '30px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '119.5%',
                  }}
                >
                  <>Conferência de vendas</>
                </Paragraph>
                <Paragraph
                  style={{
                    color: '#797979',
                    fontFamily: 'Poppins',
                    fontSize: '13px',
                  }}
                >
                  <>
                    De forma simples e automatizada, conferimos todas as vendas <br />
                    lançadas na maquineta (TEF/POS) e confrontamos com a <br />
                    operadora sobre as vendas lançadas. Com isso o cliente evita <br />
                    que a operadora deixe de capturar alguma venda por eventual <br />
                    falha na comunicação e tenha certeza de que todas as suas <br />
                    vendas foram capturadas.
                  </>
                </Paragraph>
                <Paragraph
                  style={{
                    color: '#22326E',
                    fontFamily: 'Poppins',
                    fontSize: '30px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '119.5%',
                  }}
                >
                  <>Gestão econômica</>
                </Paragraph>
                <Paragraph
                  style={{
                    color: '#797979',
                    fontFamily: 'Poppins',
                    fontSize: '13px',
                    marginBottom: '20%',
                  }}
                >
                  <>
                    Você no controle de suas vendas, conferindo se todos os <br />
                    pagamentos realizados pelas operadoras foram autorizados <br />
                    corretamente em sua conta bancária.
                  </>
                </Paragraph>
                <Row style={{ width: '80%', marginLeft: '-15%', marginTop: '-10% ' }}>
                  <Image
                    preview={false}
                    alt="Logo"
                    src={GroupFinlyImageFrom}
                    style={{ width: '120%', marginBottom: '50px' }}
                  />{' '}
                </Row>
                <Paragraph
                  style={{
                    color: '#22326E',
                    fontFamily: 'Poppins',
                    fontSize: '30px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '119.5%',
                  }}
                >
                  <>Principais benefícios</>
                </Paragraph>
                <Paragraph
                  style={{
                    color: '#797979',
                    fontFamily: 'Poppins',
                    fontSize: '13px',
                  }}
                >
                  <>
                    - Diminuição dos seus custos com taxas e maquinetas <br />
                    - Economia no tempo e equipe nas conferências <br />
                    - Evitar cobranças incorretas e atrasos de <br />
                    &nbsp;&nbsp;&nbsp;pagamentos <br />
                    - Informação sobre as taxas e os custos operacionais <br />
                    - Conferência se todas as suas vendas se foram <br />
                    &nbsp;&nbsp;&nbsp;registradas pelas operadoras <br />
                    - Monitoramento diário dos seus pagamentos <br />
                    - Acesso ao painel gerencial com múltiplas empresas <br />
                    - Integração com qualquer ERP, TEF ou outros sistemas <br />
                    - Importação das vendas diretamente das operadoras <br />- Conciliação com os principais bancos e
                    operadoras do mercado
                  </>
                </Paragraph>
              </>
            </Col>
          </Row>

          <PositiontFooter>
            <Row>
              <Col style={{ position: 'relative', left: '-6%', marginTop: '2%' }}>
                <Image
                  preview={false}
                  alt="Finly Tech"
                  src={Backgroundfooter}
                  style={{ width: '307%', position: 'relative', zIndex: 1 }}
                  height={100}
                />
              </Col>
            </Row>
          </PositiontFooter>
          <Row>
            <Col style={{ zIndex: 2, position: 'relative', left: '45%', marginTop: '-7%' }}>
              <LoginProposal onClick={showModal} type="primary" shape="default">
                Solicite uma proposta
              </LoginProposal>
            </Col>
          </Row>

          <Row>
            <ModalRateProposal isOpen={IsModalVisible} onClose={handleModalClose} />
          </Row>
        </>
      </LeftScreen>
    </Content>
  );
};
export default LandingPage;
