export function formatCurrency(value: any) {
  let numericValue = parseFloat(value);
  if (isNaN(numericValue)) {
    numericValue = 0;
  }
  numericValue = Math.abs(numericValue);
  numericValue = Number(numericValue.toFixed(2));
  const formattedValue = numericValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formattedValue;
}
