import { takeLatest, call, put } from 'redux-saga/effects';
import { changeRateApi, createRateApi, deleteRateApi, gestaoTaxaApi } from '../../target-api/api';
import {
  createGestaoTaxaFailure,
  createGestaoTaxaSuccess,
  fetchGestaoTaxaSuccess,
  updateGestaoTaxaFailure,
  updateGestaoTaxaSuccess,
  deleteGestaoTaxaSuccess,
  deleteGestaoTaxaFailure,
  fetchGestaoTaxa,
  fetchGestaoTaxaFailure,
} from './actions';
import { message } from 'antd';
import { CREATE_GESTAO_TAXA, DELETE_GESTAO_TAXA, FETCH_GESTAO_TAXA_DATA, UPDATE_GESTAO_TAXA } from './types';

export function* fetchRateRegisterSaga() {
  try {
    const data: any[] = yield call(gestaoTaxaApi);
    yield put(fetchGestaoTaxaSuccess(data));
  } catch (error: any) {
    yield put(fetchGestaoTaxaFailure(error))
    message.error(error);
  }
}

export function* createRateRegisterSaga(action: any) {
  try {
    const {
      payload: { newRate },
    }: { payload: { newRate: any } } = action;
    yield call(createRateApi, newRate);
    yield put(createGestaoTaxaSuccess());
    yield put(fetchGestaoTaxa());
  } catch (error) {
    yield put(createGestaoTaxaFailure(error));
  }
}

export function* updateRateRegisterSaga(action: any) {
  try {
    const { rateId, updatedData } = action.payload;
    yield call(changeRateApi, rateId, updatedData);
    yield put(updateGestaoTaxaSuccess());
    yield put(fetchGestaoTaxa());
    message.success('Taxa de contrato atualizada com sucesso!');
  } catch (error) {
    message.error(`Error updating rate register: ${error}`);
    yield put(updateGestaoTaxaFailure(error));
  }
}

export function* deleteRateRegisterSaga(action: any) {
  try {
    const { id } = action.payload;
    yield call(deleteRateApi, id);
    yield put(deleteGestaoTaxaSuccess());
    yield put(fetchGestaoTaxa());
    message.success('Taxa de contrato excluída com sucesso!');
  } catch (error) {
    yield put(deleteGestaoTaxaFailure(error));
  }
}

export function* watchRateRegisterSaga() {
  yield takeLatest(FETCH_GESTAO_TAXA_DATA, fetchRateRegisterSaga);
  yield takeLatest(CREATE_GESTAO_TAXA, createRateRegisterSaga);
  yield takeLatest(UPDATE_GESTAO_TAXA, updateRateRegisterSaga);
  yield takeLatest(DELETE_GESTAO_TAXA, deleteRateRegisterSaga);
}
